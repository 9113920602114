import { createSelector } from "@reduxjs/toolkit";

import type { MenuItem } from "@/VisualBuilder/components/LeftHandMenu/types";

export interface Property {
  id: number;
  name: string;
  tier: {
    title: string;
  };
  branding: {
    customSettings: {
      experienceAvatars: boolean;
    };
    previewImageURL: string;
  };
  propertyMenuItems: MenuItem[];
}

interface CurrentProperty {
  property: Property;
}

interface CurrentPropertyState {
  currentProperty: CurrentProperty;
}

export const currentPropertySelector = createSelector(
  (state: CurrentPropertyState) => state.currentProperty,
  (currentProperty) => {
    return {
      ...currentProperty,
    };
  },
);
