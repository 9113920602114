import { type AssetFiltersState, SET_ASSET_FILTERS } from "./types";

interface Action {
  type: string;
  payload: {
    searchText: "";
    tags: Record<number, boolean>;
    types: Record<string, boolean>;
    categories: Record<number, boolean>;
  };
}

const initialState: AssetFiltersState = {
  isActive: false,
  filters: {
    searchText: "",
    tags: {},
    types: {},
    categories: {},
  },
};

export function assetFiltersReducer(state: AssetFiltersState = initialState, action: Action) {
  if (action.type === SET_ASSET_FILTERS) {
    const newState = { ...state, filters: { ...state.filters, ...action.payload } };
    newState.isActive = !!(
      newState.filters.searchText ||
      Object.keys(newState.filters.tags || {}).length > 0 ||
      Object.keys(newState.filters.types || {}).length > 0 ||
      Object.keys(newState.filters.categories || {}).length > 0
    );
    return newState;
  }
  return state;
}
