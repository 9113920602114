import { Chip } from "@mui/material";

import type { DateSelectorChip } from "../types";

const styles = {
  dateChip: {
    fontFamily: "Inter",
    cursor: "pointer",
    padding: "8px",
    height: "40px",
    borderRadius: "24px",
    fontWeight: "500",
    fontSize: "16px",
  },
};

export default function DateSelectorChip(props: DateSelectorChip) {
  const { label, onClick } = props;

  return (
    <Chip
      label={label}
      sx={[
        styles.dateChip,
        {
          background: props.isActive ? "#fff" : "semanticSurface.light",
          border: props.isActive ? "2px solid" : "none",
          borderColor: props.isActive ? "semanticSelected.main" : "transparent",
          color: props.isActive ? "semanticSelected.main" : "semanticNeutral.light",
          "&:hover": {
            backgroundColor: props.isActive ? `#2066cf0d` : `#dbdbdb`,
          },
        },
      ]}
      onClick={onClick}
    />
  );
}
