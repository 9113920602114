import { useDispatch, useSelector } from "react-redux";

import type store from ".";

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;
type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useThunkDispatch = useDispatch.withTypes<AppDispatch>();
export const useThunkSelector = useSelector.withTypes<RootState>();

export type ThunkDispatch = ReturnType<typeof useThunkDispatch>;
