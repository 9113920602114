import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  elementMediaItems: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const elementMediaItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ELEMENT_MEDIA_ITEMS: {
      const { propertyId, mediaItems, status, err } = action.payload;
      const elementMediaItems = {};

      mediaItems.forEach((mediaItem) => {
        if (elementMediaItems[mediaItem.contentType.value]) {
          elementMediaItems[mediaItem.contentType.value].push(mediaItem);
        } else {
          elementMediaItems[mediaItem.contentType.value] = [mediaItem];
        }
      });

      return {
        ...state,
        [propertyId]: {
          elementMediaItems,
          status,
          err,
        },
      };
    }
    case types.SET_ELEMENT_MEDIA_ITEMS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_ELEMENT_MEDIA_ITEMS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
