import type { AxiosError } from "axios";

import type { BrandingOrganizationValues } from "@/Dashboard/pages/CreateProperty/types";

export const GET_BRANDING_ORGANIZATION_VALUES = "GET_BRANDING_ORGANIZATION_VALUES";
export const SET_BRANDING_ORGANIZATION_VALUES_REQUEST_STATUS = "SET_BRANDING_ORGANIZATION_VALUES_REQUEST_STATUS";

interface SuccessResponse {
  resp: BrandingOrganizationValues;
  err: null;
  status: "succeeded";
}

interface ErrorResponse {
  resp: null;
  err: {
    status: number;
    message?: string | AxiosError;
  };
  status: "failed";
}

export type GetOrganizationValuesResponse = SuccessResponse | ErrorResponse;
