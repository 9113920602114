import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  univVideo: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const currentUnivVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_UNIV_VIDEO:
      return { ...state, ...action.payload };
    case types.SET_CURRENT_UNIV_VIDEO_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_UNIV_VIDEO:
      return { ...initialState };
    default:
      return state;
  }
};
