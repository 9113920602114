import type { BreakpointsOptions, ThemeOptions } from "@mui/material";
import { grey, pink, red } from "@mui/material/colors";
import type { Palette, PaletteOptions, Theme as MuiTheme } from "@mui/material/styles";
import type { TypographyOptions } from "@mui/material/styles/createTypography";

export const breakpoints: BreakpointsOptions = {
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: {
    lg: 1200,
    md: 900,
    sm: 600,
    xl: 1536,
    xs: 0,
  },
};

interface Source {
  readonly palette: PaletteOptions;
  readonly typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
  readonly [value: string]: unknown; // TODO FIXME
}

export interface Theme {
  readonly color: string;
  readonly id: string;
  readonly source: Source;
}

// this is a hack to add custom properties to the Palette interface to be used in the theme
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    semanticSurface: {
      light: string;
      dark: string;
    };
    semanticSelected: {
      main: string;
      dark: string;
      light: string;
    };
    semanticDisabledForeground: {
      main: string;
      dark: string;
    };
    semanticNeutral: {
      light: string;
      main: string;
      dark: string;
    };
    semanticText: {
      light: string;
      dark: string;
    };
    semanticError: {
      light: string;
      dark: string;
    };
    semanticSuccess: {
      main: string;
      dark: string;
    };
  }
}

export const themes: Theme[] = [
  {
    color: "#8f0339",
    id: "default",
    source: {
      typography: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        // body1: {
        //   fontSize: '14px'
        // }
      },
      palette: {
        primary: {
          main: "#8f0339",
        },
        secondary: {
          light: "#fff",
          main: grey[50],
          dark: grey[500],
        },
        text: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          main: "rgba(0, 0, 0, 0.87)", // TODO FIXME doesn't seem to exist, can this be removed?
        },
        info: {
          main: "#6dd400",
          contrastText: "#fff",
        },
        error: red,
        background: {
          default: grey[100],
        },
        selected: {
          main: "rgb(32, 102, 207)",
          contrastText: "#fff",
        },
        lightSelected: {
          main: "rgba(201, 217, 243)",
        },
        semanticSuccess: {
          main: "#006600",
          dark: "#00AD00",
        },
        semanticPrimary: {
          main: "#8F0339",
          dark: "#5B0224",
        },
        semanticSelected: {
          main: "#2066CF",
          dark: "#4182E1",
          light: "#c9d9f3",
        },
        semanticSurface: {
          light: "#E8E8E8",
          dark: "#171717",
        },
        semanticNeutral: {
          light: "#5D5D5D",
          main: "#747474",
          dark: "#8B8B8B",
        },
        semanticDisabledForeground: {
          main: "#B9B9B9",
          dark: "#464646",
        },
        semanticText: {
          light: "#ffffff",
          dark: "#000000",
        },
        semanticError: {
          light: "#D60000",
          dark: "#FF1A1A",
        },
      },
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&.MuiTableRow-hover": {
                "&:hover": {
                  backgroundColor: "#fff",
                },
              },
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ theme }: { theme: MuiTheme }) => ({
              borderRadius: "0",
              boxShadow: "none !important",
              textTransform: "initial",
              "&.MuiButton-outlined.MuiButton-outlinedPrimary": {
                borderColor: theme.palette.semanticNeutral.dark,
                "&:hover": { borderColor: theme.palette.semanticNeutral.dark },
              },
            }),
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: "0",
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            input: {
              backgroundColor: grey[100],
              borderRadius: 0,
              padding: "8px",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: "2px",
              width: "100%",
            },
            input: {
              backgroundColor: grey[100],
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: "none",
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            input: {
              borderRadius: 0,
              paddingTop: "10px",
            },
            root: {
              backgroundColor: grey[100],
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              backgroundColor: grey[100],
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 0,
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              color: "#8f0339",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h6: {
              color: "#8f0339",
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: "none",
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              color: "#8f0339",
              minHeight: "48px !important",
            },
            content: {
              margin: "12px 0 !important",
            },
          },
        },
      },
    },
  },
  {
    color: red[500],
    id: "red",
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
      },
    },
  },
  {
    color: red[500],
    id: "green",
    source: {
      palette: {
        primary: red,
        secondary: red,
        error: red,
      },
    },
  },
];

interface ThemeSource {
  readonly isNightModeOn: boolean;
  readonly source: "default";
}

export default function getThemeSource(t: ThemeSource, ts: readonly Theme[]): ThemeOptions {
  if (ts) {
    for (const { id, source } of ts) {
      if (id === t.source) {
        const palette = source == null ? {} : source.palette;
        const typography = source == null ? {} : source.typography;

        return {
          ...source,
          breakpoints,
          palette: {
            ...palette,
            mode: t.isNightModeOn ? "dark" : "light",
          },
          typography: {
            ...typography,
          },
        };
      }
    }
  }

  // Default theme
  return {
    breakpoints,
    palette: {
      mode: t.isNightModeOn ? "dark" : "light",
    },
    typography: {
      // useNextVariants: true, // TODO FIXME doesn't seem to exist
    },
  };
}
