import { Outlet } from "react-router-dom";

import GoogleAnalytics from "@/containers/Root/GoogleAnalytics";

const RootLayout = () => {
  return (
    <>
      <GoogleAnalytics />
      <Outlet />
    </>
  );
};

export default RootLayout;
