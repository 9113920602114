import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  hotspots: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const hotspotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HOTSPOTS: {
      const { hotspots, status, err } = action.payload;
      return { ...state, hotspots, status, err };
    }
    case types.SET_HOTSPOTS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_HOTSPOTS:
      return { ...initialState };
    default:
      return state;
  }
};
