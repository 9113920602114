export const PUBLIC_ROUTE = {
  SIGN_IN: "/signin",
  NOT_FOUND: "*",
  PROPERTY: {
    ASSET_LIBRARY: {
      VIEW: "/properties/:propertyId/media/:mediaId",
    },
    EXPERIENCES: {
      VIEW: "/properties/:propertyId/experiences/:experienceId",
      ALL: "/properties/:propertyId/all-assets-share",
      SINGLE: "/properties/:propertyId/single-asset-share/mediaItem/:mediaItemId",
    },
  },
};

export const ADMIN_ROUTE = {
  ASSET_LIBRARIES: {
    LIST: "/media",
    VIEW: "/media/:mediaId",
  },
  COACHES: {
    LIST: "/coaches",
    VIEW: "/coaches/:coachId",
  },
  PROPERTIES: {
    LIST: "/properties",
    ADD: "/properties/create",
  },
  UNIVERSITY: {
    LIST: "/university",
    VIEW: "/university/:universityId",
  },
  USERS: {
    LIST: "/users",
    VIEW: "/users/:userId",
  },
};

export const DASHBOARD_ROUTE = {
  HOME: "/",
  ACCOUNTS: {
    LIIST: "/accounts",
  },
  ANALYTICS: {
    LIST: "/properties/:propertyId/analytics",
    VIEW: "/properties/:propertyId/analytics/:cardId",
  },
  ASSET_LIBRARY: {
    LIST: "/properties/:propertyId/media",
  },
  COLLECTIONS: {
    LIST: "/properties/:propertyId/collections",
  },
  CONTACTS: {
    LIST: "/properties/:propertyId/contacts",
    VIEW: "/properties/:propertyId/contacts/:contactId",
  },
  SETTINGS: {
    VIEW: "/properties/:propertyId/settings",
    ICONS: "/properties/:propertyId/settings/icons",
  },
  UNIVERSITY: {
    LIST: "/properties/:propertyId/university",
  },
  USERS: {
    LIST: "/properties/:propertyId/users",
    VIEW: "/properties/:propertyId/users/:userId",
  },
  EXPERIENCES: {
    LIST: "/properties/:propertyId/experiences",
  },
  PROFILE: "/profile",
};

export const VISUAL_BUILDER_ROUTE = {
  HOME: "/properties/:propertyId",
  EXPERIENCES: {
    COLLECT: "/properties/:propertyId/experiences/:experienceId/collect",
    CUSTOMIZE: "/properties/:propertyId/experiences/:experienceId/customize",
  },
};
