import * as types from "./types";

interface ActionPayload {
  id: number;
  ids: number[];
  categories: Record<number, boolean>;
  items: Record<number, boolean>;
  expandedAssetCategories: Record<number, boolean>;
  expanded: boolean;
}

interface State {
  assetCategorySelection: Record<number, boolean>; // which asset categories are selected
  assetCategoryExpandedSelection: Record<number, boolean>; // which asset categories are expanded
  assetItemSelection: Record<number, boolean>; // which assets are expanded
}

interface Action {
  type: string;
  payload: ActionPayload;
}

export function assetSelectionReducer(
  state: State = {
    assetCategorySelection: {},
    assetCategoryExpandedSelection: {},
    assetItemSelection: {},
  },
  action: Action,
) {
  switch (action.type) {
    case types.TOGGLE_ASSET_CATEGORY_SELECTION: {
      const { id } = action.payload;

      const assetCategorySelection = {
        ...state.assetCategorySelection,
        [id]: !state.assetCategorySelection[id],
      };
      if (!assetCategorySelection[id]) {
        delete assetCategorySelection[id];
      }

      return { ...state, assetCategorySelection };
    }
    case types.SELECT_MULTIPLE_ASSET_CATEGORY_ITEMS: {
      const { ids } = action.payload;

      const assetCategorySelection: Record<number, boolean> = {};

      for (const id of ids) {
        assetCategorySelection[id] = true;
      }

      return { ...state, assetCategorySelection };
    }
    case types.SELECT_MULTIPLE_ASSET_ITEMS: {
      const { ids } = action.payload;

      const assetItemSelection: Record<number, boolean> = {};

      for (const id of ids) {
        assetItemSelection[id] = true;
      }

      return { ...state, assetItemSelection };
    }
    case types.TOGGLE_ASSET_ITEM_SELECTION: {
      const { id } = action.payload;

      const assetItemSelection = {
        ...state.assetItemSelection,
        [id]: !state.assetItemSelection[id],
      };
      if (!assetItemSelection[id]) {
        delete assetItemSelection[id];
      }

      return { ...state, assetItemSelection };
    }
    case types.TOGGLE_ASSET_CATEGORY_EXPANDED_SELECTION: {
      const { id, expanded } = action.payload;

      const assetCategoryExpandedSelection = {
        ...state.assetCategoryExpandedSelection,
        [id]: expanded,
      };
      if (!assetCategoryExpandedSelection[id]) {
        delete assetCategoryExpandedSelection[id];
      }

      return { ...state, assetCategoryExpandedSelection };
    }
    case types.SET_ASSET_SELECTION: {
      const { categories: assetCategorySelection, items: assetItemSelection } = action.payload;

      return { ...state, assetCategorySelection, assetItemSelection };
    }
    case types.RESET_ASSET_SELECTION: {
      return {
        ...state,
        assetCategorySelection: {},
        assetItemSelection: {},
      };
    }
    default:
      return state;
  }
}
