import * as types from "./types";

const initialState = {
  selectMode: false,
  selectAll: false,
  selectedItems: {},
};

export const currentSelectedItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECT_MODE: {
      return { ...state, ...action.payload, changed: new Date().getTime() };
    }
    case types.SET_SELECT_ALL: {
      return { ...state, ...action.payload, changed: new Date().getTime() };
    }
    case types.ADD_CURRENT_SELECTED_ITEMS: {
      const { items = {} } = action.payload;
      const selectedItems = { ...state.selectedItems, ...items };

      return {
        ...state,
        selectedItems,
        changed: new Date().getTime(),
      };
    }
    case types.REMOVE_CURRENT_SELECTED_ITEMS: {
      const { items = [] } = action.payload;
      const selectedItems = { ...state.selectedItems };
      items.forEach((id) => delete selectedItems[id]);

      return {
        ...state,
        selectedItems,
        changed: new Date().getTime(),
      };
    }
    case types.RESET_CURRENT_SELECTED_ITEMS: {
      return {
        ...state,
        selectedItems: {},
        changed: new Date().getTime(),
      };
    }
    default:
      return state;
  }
};
