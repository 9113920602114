import { createSelector } from "@reduxjs/toolkit";

interface State {
  loadingStatus: {
    dataLoading: boolean;
    contentLoading: boolean;
    dashboardLoading: boolean;
    isLoading: boolean;
    isPublicView: boolean;
    splashTimeout: boolean;
  };
}

const initialState: State["loadingStatus"] = {
  dataLoading: true,
  contentLoading: true,
  dashboardLoading: true,
  isLoading: false,
  isPublicView: false,
  splashTimeout: false,
};

export const loadingStatusSelector = createSelector(
  (state: State) => state.loadingStatus,
  (loadingStatus) => {
    const values: State["loadingStatus"] = {
      ...initialState,
      ...loadingStatus,
    };

    values.isLoading = values.dataLoading || values.contentLoading;

    if (values.isPublicView && !values.splashTimeout) {
      values.isLoading = true;
    }

    return values;
  },
);
