import * as types from "./types";
import { getNewList } from "../../../utils/helper";

const initialState = {};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS: {
      const { notifications, status, err, query } = action.payload;
      const { category } = query;
      const { notification: stateNotifications = [] } = state[category] || {};
      const newNotifications = getNewList(query, stateNotifications, notifications);
      return {
        ...state,
        [category]: {
          notifications: newNotifications,
          status,
          err,
          noResults: !newNotifications.length,
        },
      };
    }
    case types.GET_NOTIFICATIONS_OPTIONS:
      return { ...state, ...action.payload };
    case types.SET_NOTIFICATIONS_REQUEST_STATUS: {
      const { category } = action.payload;
      return {
        ...state,
        [category]: { ...(state[category] || {}), ...action.payload },
      };
    }
    case types.RESET_NOTIFICATIONS:
      return { ...initialState };
    default:
      return state;
  }
};
