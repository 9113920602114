import { GET_FLAGS, SET_FLAGS_REQUEST_STATUS } from "./types";

export const flagsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FLAGS: {
      const { flags, status, err } = action.payload;
      const newFlags = flags.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
      }, {});

      return {
        ...state,
        flags: newFlags,
        status,
        err,
      };
    }
    case SET_FLAGS_REQUEST_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        status,
      };
    }
    default:
      return state;
  }
};
