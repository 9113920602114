import { createSelector } from "@reduxjs/toolkit";

import type { State } from "../types";

export const getSimpleValue = (
  state: { simpleValues: { [key in string]: object } },
  name: string,
  defValue: object,
) => {
  const { [name]: simpleValue = defValue } = state.simpleValues
    ? state.simpleValues
    : ({} as { [key in string]: object });

  return simpleValue;
};

export const simpleValuesSelector = createSelector(
  (state: State) => state.simpleValues,
  (simpleValues) => {
    return { ...simpleValues };
  },
);
