import { createSelector } from "@reduxjs/toolkit";

interface CurrentSelectedItes {
  selectAll: boolean;
}

interface CurrenSelectedItemsState {
  currentSelectedItems: CurrentSelectedItes;
}

export const currentSelectedItemsSelector = createSelector(
  (state: CurrenSelectedItemsState) => state.currentSelectedItems,
  (currentSelectedItems) => {
    return { ...currentSelectedItems };
  },
);
