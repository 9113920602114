import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, type ButtonProps, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => {
  return {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    width: "100px",
    height: "40px",
    "&.MuiButton-root": {
      borderRadius: "20px",
      padding: "8px 16px",
    },
    "&.MuiButton-text": {
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.semanticSurface.light,
      },
      "&:focus": {
        boxShadow: `0 0 0 1px ${theme.palette.semanticSelected.main}!important`,
        backgroundColor: "transparent",
        "& .MuiTouchRipple-root": {
          opacity: 0,
        },
      },
      "&:active": {
        boxShadow: `none !important`,
        backgroundColor: theme.palette.semanticSurface.light,
      },
    },

    "&.Mui-disabled": {
      color: theme.palette.semanticDisabledForeground.main,
      backgroundColor: "transparent",
    },
  };
});

interface BackButtonProps extends Omit<ButtonProps, "startIcon"> {
  readonly variant?: "text";
}

export default function BackButton({ disabled = false, onClick, variant = "text" }: BackButtonProps) {
  return (
    <StyledButton
      variant={variant}
      disabled={disabled}
      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      onClick={onClick}
    >
      Back
    </StyledButton>
  );
}
