import { createSelector } from "@reduxjs/toolkit";

import type { Flags } from "@/store/types";

import { REQUEST_STATUSES } from "../../../constants";

interface State {
  flags: {
    err: Error | null;
    flags: Flags;
    status: REQUEST_STATUSES;
  };
}

const initialState: State["flags"] = {
  err: null,
  flags: {},
  status: REQUEST_STATUSES.IDLE,
};

export const flagsSelector = createSelector(
  (state: State) => state.flags,
  (flags) => {
    return {
      ...initialState,
      ...flags,
    };
  },
);
