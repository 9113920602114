import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ToastContainer, cssTransition, toast } from "react-toastify";

import ToastMessage from "./ToastMessage";
import { useUrlParams } from "../../hooks";
import { simpleValuesSelector, visualBuilderDataSelector } from "../../store/selectors";

export default function ToastElement() {
  const params = useUrlParams();

  const { visualBuilderData } = useSelector(visualBuilderDataSelector(params));
  const { "browse-experiences": isDialogOpen = false } = useSelector(simpleValuesSelector);

  const [toastStyle, setToastStyle] = useState(false);
  const [carousel, setCarousel] = useState(false);
  const [carouselState, rightDrawerState, rightDrawerAutoHideState] = useSelector(() => {
    const {
      carousel: carouselStoreState,
      rightDrawer: rightDrawerStoreState,
      rightDrawerAutoHide: rightDrawerAutoHideStoreState,
    } = visualBuilderData;

    return [carouselStoreState, rightDrawerStoreState, rightDrawerAutoHideStoreState];
  }, shallowEqual);

  const { success, warning, error, info, changed } = toast;

  if (!changed) {
    toast.success = (content, options = {}) => {
      const toastId = new Date().getTime();
      const config = { htmlColor: "#6DD400", toastId, containerId: "light" };
      if (options.containerId === "dark") {
        config.containerId = "dark";
      }

      config.showMore = !!options.showMore;
      const { containerId } = config;

      return success(
        <ToastMessage type="success" message={content} config={config} toastId={toastId} containerId={containerId} />,
        { ...config, ...options },
      );
    };

    toast.warning = (content, options = {}) => {
      const toastId = new Date().getTime();
      const config = { htmlColor: "#000", toastId, containerId: "light" };
      if (options.containerId === "dark") {
        config.containerId = "dark";
        config.htmlColor = "#fff";
      }

      config.showMore = !!options.showMore;
      const { containerId } = config;

      return warning(
        <ToastMessage type="warning" message={content} config={config} toastId={toastId} containerId={containerId} />,
        { ...config, ...options },
      );
    };

    toast.error = (content, options = {}) => {
      const toastId = new Date().getTime();
      const config = { htmlColor: "#000", toastId, containerId: "light" };
      if (options.containerId === "dark") {
        config.containerId = "dark";
        config.htmlColor = "#fff";
      }

      config.showMore = !!options.showMore;
      const { containerId } = config;
      return error(
        <ToastMessage type="error" message={content} config={config} toastId={toastId} containerId={containerId} />,
        { ...config, ...options },
      );
    };

    toast.info = (content, options = {}) => {
      const toastId = new Date().getTime();
      const config = { htmlColor: "#000", toastId, containerId: "light" };
      if (options.containerId === "dark") {
        config.containerId = "dark";
        config.htmlColor = "#fff";
      }

      config.showMore = !!options.showMore;
      const { containerId } = config;

      return info(
        <ToastMessage type="info" message={content} config={config} toastId={toastId} containerId={containerId} />,
        { ...config, ...options },
      );
    };

    toast.changed = true;
  }

  useEffect(() => {
    if (carousel !== carouselState) {
      setCarousel(carouselState);
    }

    if (rightDrawerState && !rightDrawerAutoHideState) {
      if (!toastStyle) setToastStyle(true);
    } else if (toastStyle) setToastStyle(false);
  }, [carouselState, rightDrawerState, rightDrawerAutoHideState]);

  const FadeTransition = cssTransition({
    enter: "fadeIn",
    exit: "fadeOut",
  });

  return (
    <>
      <ToastContainer
        autoClose={5000}
        theme="light"
        toastClassName="light-toast"
        pauseOnHover={false}
        position="bottom-right"
        icon={false}
        draggable={false}
        transition={FadeTransition}
        hideProgressBar
        containerId="light"
      />
      <ToastContainer
        autoClose={5000}
        theme="dark"
        toastClassName="dark-toast"
        pauseOnHover={false}
        position="bottom-right"
        icon={false}
        draggable={false}
        transition={FadeTransition}
        hideProgressBar
        containerId="dark"
        style={
          isDialogOpen
            ? {}
            : {
                right: toastStyle ? 325 : 83,
                bottom: carousel ? 147 : 3,
              }
        }
      />
    </>
  );
}
