import { random } from "./random";

export class RetryManager {
  // the number of consecutive times reconnect has been attempted
  #count = 0;
  /**
   * maxCount - total allowed number of consecutive attempts to reconnect
   *
   * To stagger client reconnect attempts we use random-variance exponential
   * backoff.
   *
   * Attempts to reconnect will occur quickly at first but will quickly delay
   * for longer and longer until giving up. The idea being, the longer the
   * reconnect continues to fail the longer it will continue to fail and
   * after a short time period it is unlikely the user would remain waiting
   * for a connection to successfully come back.
   *
   * The variance is to prevent all clients to attempt reconnection
   * simultaneously as that could create a continual crash loop.
   *
   * attempt | delay (milliseconds)  | cumulative wait
   * 1       | 1000   +/- variance/2 | ~1 second
   * 2       | 2000   +/- variance/2 | ~3 seconds
   * 3       | 4000   +/- variance/2 | ~7 seconds
   * 4       | 8000   +/- variance/2 | ~16 seconds
   * 5       | 16000  +/- variance/2 | ~32 seconds
   * 6       | 32000  +/- variance/2 | ~1 minute
   * 7       | 64000  +/- variance/2 | ~2 minute
   * 8       | 128000 +/- variance/2 | ~4+ minute
   */
  #maxCount = 8;
  // the millisecond timestamp (Date.now()) of when retries started
  #started;
  // 0.3 === 30% - the amount to vary the exponential backoff timeout (delay)
  #variance = 0.3;

  constructor(max = 8) {
    this.#maxCount = parseInt(`${max}`, 10);

    if (!isFinite(this.#maxCount) || isNaN(this.#maxCount)) {
      throw new Error(`Invalid maxCount provided: ${max}`);
    }
  }

  attempt() {
    if (this.#count >= this.#maxCount) {
      const total = (Date.now() - this.#started) / 1000;

      throw new Error(`Attempted reconnect ${this.#count} times; in ${total} seconds.`);
    }

    this.#count += 1;
    this.#started = this.#started ?? Date.now();
  }

  getCount() {
    return this.#count;
  }

  getTimeout(ratio = random()) {
    const maxDelay = 2 ** this.#count * 1000;
    const varianceMultiple = ratio * this.#variance + (1 - this.#variance / 2);

    return parseInt(maxDelay * varianceMultiple, 10);
  }

  reset() {
    this.#count = 0;
    this.#started = null;
  }
}
