import type { AxiosError } from "axios";

import { REQUEST_STATUSES } from "../constants";
import instance from "./instance";
import { getErrorResponse } from "../utils/helper";

export class PublicService {
  static async getMediaItem(propertyId: number, mediaItemId: number) {
    try {
      const response = await instance({
        method: "get",
        url: `/api/v2/public/properties/${propertyId}/media-items/${mediaItemId}`,
      });

      return {
        resp: response.data as Record<string, unknown>,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err: unknown) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err as AxiosError),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
