import { useCallback, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { CONTENT_TYPE } from "@/constants";
import { PublicService, PublicVisualizationService } from "@/services";
import { selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";

import { currentEnv } from "../../config/envSettings";
import { currentMediaItemSelector } from "../../store/selectors";

const env = currentEnv();
const isLocal = [`dev`, `local`].includes(env);

ReactGA.initialize(import.meta.env.VITE_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
  gaOptions: {
    debug_mode: isLocal,
  },
  gtagOptions: {
    debug_mode: isLocal,
  },
});

const contentTypeMap = new Map<string, string>([
  [CONTENT_TYPE.THREE_DIMENSIONAL_MODEL, "3d_model"],
  [CONTENT_TYPE.VIDEO, "video"],
  [CONTENT_TYPE.PDF, "pdf"],
  [CONTENT_TYPE.THREE_HUNDRED_SIXTY_PHOTO, "360_photo"],
  [CONTENT_TYPE.LINK, "link"],
  [CONTENT_TYPE.AUDIO, "audio"],
  [CONTENT_TYPE.PHOTO, "photo"],
  [CONTENT_TYPE.VIRTUAL_TOUR, "virtual_tour"],
]);

function getIdsFromUrl(pathname: string | undefined) {
  if (!pathname) return { propertyId: "", experienceId: "", mediaId: "" };

  const pathParts = pathname.split("/");
  const propertyId = pathParts[pathParts.indexOf("properties") + 1];
  const experienceId = pathParts[pathParts.indexOf("experiences") + 1];
  const mediaId = pathParts[pathParts.indexOf("media") + 1];

  return { propertyId, experienceId, mediaId };
}

function logAnalyticsEvent(event: string, params: Record<string, string>) {
  ReactGA.event(event, params);
}

export default function GoogleAnalytics() {
  const location = useLocation();
  const { mediaItem } = useSelector(currentMediaItemSelector);
  const user = useThunkSelector(selectUser);
  const userId = user?.id || "";

  const { propertyId, experienceId, mediaId } = getIdsFromUrl(location.pathname);

  const shareId = useMemo(() => new URLSearchParams(location.search).get("shareId"), [location.search]);

  // Check if the URL contains all-assets-share, which is "Full Collection Share" in Visual Builder
  const isAllAssetsShare = useMemo(() => location.pathname?.includes("all-assets-share"), [location.pathname]);

  const fetchExperienceOwnerAndSendEvent = useCallback(async () => {
    try {
      const response = isAllAssetsShare
        ? await PublicVisualizationService.getPublicMediaShareOwner(propertyId, shareId)
        : await PublicVisualizationService.getPublicExperienceOwner(propertyId, experienceId, shareId);

      const { title = "Unknown Experience", ownerId } = response?.resp || {};
      logAnalyticsEvent("view_experience", {
        experience_id: isAllAssetsShare ? "E_FullCollection" : `E_${experienceId}`,
        experience_name: isAllAssetsShare ? `${title} Full Collection` : title,
        owner_id: ownerId ? `U_${ownerId}` : "",
        property_id: `P_${propertyId}`,
        share_id: `S_${shareId}`,
      });
    } catch (err) {
      console.error("Error fetching experience owner:", err);
      logAnalyticsEvent("view_experience", {
        experience_id: isAllAssetsShare ? "E_FullCollection" : `E_${experienceId}`,
        property_id: `P_${propertyId}`,
        share_id: `S_${shareId}`,
      });
    }
  }, [propertyId, experienceId, shareId, isAllAssetsShare]);

  const fetchMediaItemAndSendEvent = useCallback(async () => {
    if (!propertyId || !mediaId) return;

    try {
      const { resp } = (await PublicService.getMediaItem(Number(propertyId), Number(mediaId))) as unknown as {
        status: string;
        resp: {
          contentType: { value: string };
        };
      };

      const contentType = contentTypeMap.get(resp.contentType.value) ?? "unknown";
      logAnalyticsEvent("select_content", {
        content_id: `C_${mediaId}`,
        content_type: contentType,
      });
    } catch (err) {
      console.error("Error fetching media item:", err);
      logAnalyticsEvent("select_content", {
        content_id: `C_${mediaId}`,
        content_type: "unknown",
      });
    }
  }, [propertyId, mediaId]);

  useEffect(() => {
    // Set user ID if available.
    // Docs: https://support.google.com/analytics/answer/9213390
    ReactGA.set({ userId });
  }, [userId]);

  useEffect(() => {
    if (shareId && (experienceId || isAllAssetsShare)) {
      void fetchExperienceOwnerAndSendEvent();
    }
  }, [fetchExperienceOwnerAndSendEvent, shareId, experienceId, isAllAssetsShare, propertyId]);

  useEffect(() => {
    if (mediaItem?.id && shareId) {
      // Docs: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_content
      logAnalyticsEvent("select_content", {
        content_id: `C_${mediaItem.id}`,
        content_type: contentTypeMap.get(mediaItem.contentType.value) ?? "unknown",
      });
    }
  }, [propertyId, mediaItem?.id, shareId]);

  useEffect(() => {
    // being used when path matches /properties/:propertyId/media/:mediaId
    // will be populated in Channel and Asset Analytics Reports
    if (mediaId) {
      void fetchMediaItemAndSendEvent();
    }
  }, [fetchMediaItemAndSendEvent, mediaId, propertyId]);

  return null;
}
