import type { BrandingOrganizationValues } from "@/Dashboard/pages/CreateProperty/types";

import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

interface ActionPayload {
  brandingOrganizationValues?: BrandingOrganizationValues;
  status?: string;
  err?: unknown;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

const initialState = {
  brandingOrganizationValues: {
    brandingParents: [],
    brands: [],
    management: [],
    ownership: [],
  } as BrandingOrganizationValues,
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export function brandingOrganizationValuesReducer(state = initialState, action: Action) {
  if (action.type === types.GET_BRANDING_ORGANIZATION_VALUES) {
    const { status, err, brandingOrganizationValues } = action.payload;
    return {
      ...state,
      brandingOrganizationValues,
      status,
      err,
    };
  }

  if (action.type === types.SET_BRANDING_ORGANIZATION_VALUES_REQUEST_STATUS) {
    return {
      ...state,
      status: action.payload.status,
    };
  }

  return state;
}
