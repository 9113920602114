import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";
import { getTopLevelRole } from "@/utils/roleUtils";

import loadPendo from "./loadPendo";
import { currentPropertySelector } from "../../store/selectors";

export default function AnalyticsTags() {
  const user = useThunkSelector(selectUser);
  const { property } = useSelector(currentPropertySelector);
  const { isAuthenticated } = useAuth0();
  const { id: userId, email, name, role, propertyRoles = {} } = user || { id: "", email: "", name: "", role: "" };
  const topRole: string = getTopLevelRole([role, ...Object.values(propertyRoles)]);

  const [pendoLoaded, setPendoLoaded] = useState(false);
  const [pendoInitialized, setPendoInitialized] = useState(false);

  useEffect(() => {
    try {
      if (!pendoLoaded) {
        loadPendo(import.meta.env.VITE_APP_PENDO_API_KEY);
        console.log("Pendo script loaded.");
        setPendoLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  // We need to watch for property changes so we can update Pendo metadata options.
  useEffect(() => {
    try {
      // Ensure Pendo is actually loaded and initialized.
      if (pendoLoaded && window.pendo && pendoInitialized) {
        // If we our new property ID is valid, we should make sure
        // we're sending events. Otherwise, if there's no active property,
        // stop sending events.
        if (property.name) {
          // If Pendo is initialized but we're changing properties, just update the metadata.
          window.pendo.updateOptions({
            account: {
              id: property.id,
              account_name: property.name,
              account_tier: property.tier.title,
            },
            visitor: {
              email,
              full_name: name,
              id: String(userId),
              role: topRole || "NO ROLE",
            },
          });

          // If we're not sending events with a valid property, start.
          if (!window.pendo.isSendingEvents()) {
            window.pendo.startSendingEvents();
          }
        } else if (!property.name && window.pendo.isSendingEvents()) {
          // If Pendo is sending events without a valid property, stop.
          window.pendo.stopSendingEvents();
        }
      } else if (pendoLoaded && window.pendo && !pendoInitialized && isAuthenticated && userId) {
        // If Pendo is loaded but not initialized, and we have everything to initialize, do so.
        window.pendo.initialize({
          account: {
            id: property.id,
            account_name: property.name,
            account_tier: property.tier.title,
          },
          visitor: {
            email,
            full_name: name,
            id: String(userId),
            role: role || "NO ROLE",
          },
        });
        console.log(`Pendo initialized.`);
        setPendoInitialized(true);

        // Start immediately sending events if there's a valid property.
        if (property.name) {
          window.pendo.startSendingEvents();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [isAuthenticated, pendoLoaded, property.name, role]);

  return <></>;
}
