import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";

export default function Tracer() {
  const [tracerInitialized, setTracerInitialized] = useState(false);

  useEffect(() => {
    try {
      if (tracerInitialized) {
        return;
      }

      if (!import.meta.env.VITE_APP_DD_TOKEN) {
        console.info("Datadog RUM token not found, skipping initialization");
        return;
      }

      const logSampleRate = +(import.meta.env.VITE_APP_DD_LOG_SAMPLE_RATE ?? "10"); // TODO FIXME remove default
      const sessionReplaySampleRate = +(import.meta.env.VITE_APP_DD_SESSION_REPLAY_SAMPLE_RATE ?? "0"); // TODO FIXME remove default
      const rumSampleRate = +(import.meta.env.VITE_APP_DD_RUM_SAMPLE_RATE ?? "10"); // TODO FIXME remove default
      const traceSampleRate = +(import.meta.env.VITE_APP_DD_TRACE_SAMPLE_RATE ?? "10"); // TODO FIXME remove default

      console.info("Logs sample rate: ", logSampleRate);
      console.info("TOKEN: ", import.meta.env.VITE_APP_DD_TOKEN);
      console.info("RUM sample rate: ", rumSampleRate);
      console.info("Session replay rate: ", sessionReplaySampleRate);

      datadogRum.init({
        applicationId: "22e3ebb5-4427-4b8c-b254-dbec97d8264c", // TODO FIXME should be env var
        clientToken: import.meta.env.VITE_APP_DD_TOKEN,
        site: "datadoghq.com",
        service: "ttx-frontend",
        env: import.meta.env.VITE_APP_ENV ?? "dev", // TODO FIXME remove default
        version: import.meta.env.VITE_APP_VERSION ?? "0.0.0", // TODO FIXME remove default
        sessionSampleRate: rumSampleRate,
        sessionReplaySampleRate: sessionReplaySampleRate,
        traceSampleRate: traceSampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
          (url) => {
            const regex = /^https:\/\/.*truetour\.app\/api.*$/;
            return regex.test(url);
          },
        ],
      });
      console.info("Datadog RUM initialized");
      datadogLogs.init({
        service: "ttx-frontend",
        env: import.meta.env.VITE_APP_ENV ?? "dev", // TODO FIXME remove default
        clientToken: import.meta.env.VITE_APP_DD_TOKEN,
        version: import.meta.env.VITE_APP_VERSION ?? "0.0.0", // TODO FIXME remove default
        site: "datadoghq.com",
        forwardErrorsToLogs: true,
        silentMultipleInit: true,
        sessionSampleRate: logSampleRate,
      });

      console.info("Datadog Logs initialized");
      setTracerInitialized(true);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return <></>;
}
