import { CssBaseline, StyledEngineProvider, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { shallowEqual, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";

import router from "@/routes";
import { themeSourceSelector } from "@/store/themeSource/selectors";

import Tracer from "./Tracer";
import OpenGraphTags from "../../VisualBuilder/components/OpenGraphTags";
import type { AppConfig } from "../../config/config";
import getThemeSource from "../../config/themes";

interface RootProps {
  readonly appConfig: AppConfig;
}

export default function Root(props: RootProps) {
  const { appConfig } = props;

  const themeSource = useSelector(themeSourceSelector, shallowEqual);
  const source = getThemeSource(themeSource, appConfig.themes);
  const theme = createTheme(source);

  return (
    <>
      <Tracer />
      <OpenGraphTags />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </>
  );
}
