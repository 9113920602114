import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  mediaItems: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
  count: 0,
};

export const mediaItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MEDIA_ITEMS: {
      const { propertyId, mediaItems, count, status, err, query } = action.payload;
      const newMediaItems = getNewList(query, state[propertyId].mediaItems, mediaItems);
      return {
        ...state,
        [propertyId]: {
          mediaItems: newMediaItems,
          status,
          err,
          noResults: !count,
          count,
        },
      };
    }
    case types.SET_MEDIA_ITEMS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_MEDIA_ITEMS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
