import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  mediaItemTags: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const mediaItemTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIA_ITEM_TAGS: {
      const { propertyId, mediaItemTags, status, err, query } = action.payload;
      const newMediaItemTags = getNewList(query, state.mediaItemTags, mediaItemTags);
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          mediaItemTags: newMediaItemTags,
          status,
          err,
          noResults: !newMediaItemTags.length,
          changed: new Date().getTime(),
        },
      };
    }
    case types.SET_MEDIA_ITEM_TAGS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_MEDIA_ITEM_TAGS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return initialState;
    }
    default:
      return state;
  }
};
