import { ROLES } from "@/constants";

export const rolesLevel: Record<string, number> = {
  [ROLES.COACH_ADMIN]: 1,
  [ROLES.PROPERTY_ADMIN]: 2,
  [ROLES.SALESPERSON]: 3,
  [ROLES.HYBRID_USER]: 4,
};

export const getTopLevelRole = (roles: string[]): string =>
  roles.reduce(
    (topRole, currentRole) => (rolesLevel[currentRole] < rolesLevel[topRole] ? currentRole : topRole),
    roles[0],
  );
