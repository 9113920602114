import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  experienceMembers: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const experienceMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPERIENCE_MEMBERS: {
      const { propertyId, experienceMembers, status, err, query } = action.payload;
      const newExperienceMembers = getNewList(query, state.experienceMembers, experienceMembers);
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          experienceMembers: newExperienceMembers,
          status,
          err,
          noResults: !newExperienceMembers.length,
        },
      };
    }
    case types.SET_EXPERIENCE_MEMBERS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_EXPERIENCE_MEMBERS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return initialState;
    }
    default:
      return state;
  }
};
