export const SET_QUERY = "SET_QUERY";
export const RESET_QUERY = "RESET_QUERY";

export interface Query {
  offset?: number;
  limit?: number;
  sortField?: string;
  sortOrientation?: "asc" | "desc";
  searchText?: string;
  where?: Record<string, unknown>;
}
