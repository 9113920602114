import { PURGE } from "redux-persist";

import * as types from "./types";

const initialState = {
  sideBar: true,
};

export const persistentValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_PERSISTENT_VALUE_CHANGED:
      return { ...state, [action.id]: action.value };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
