import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  messages: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MESSAGES: {
      const { messages, status, err, query } = action.payload;
      const newMessages = getNewList(query, state.messages, messages);
      return {
        ...state,
        messages: newMessages,
        status,
        err,
      };
    }
    case types.SET_MESSAGES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_MESSAGES:
      return { ...initialState };
    default:
      return state;
  }
};
