import { SWITCH_NIGHT_MODE, UPDATE_THEME } from "./types";

const initialState = {
  isNightModeOn: false,
  source: "default",
};

export default function themeSource(state = initialState, action) {
  switch (action.type) {
    case UPDATE_THEME:
      return { ...state, source: action.theme };
    case SWITCH_NIGHT_MODE:
      return { ...state, isNightModeOn: action.isNightModeOn };
    default:
      return state;
  }
}
