import { differenceInDays, parseISO } from "date-fns";

import { DATE_CHIP_OPTIONS, ORDER_BY, type OrderBy, SORT_ORDER, type SortOrder } from "./enums";
import type {
  AssetTableData,
  ChannelData,
  DateRange,
  ExperienceTableData,
  FormattedRows,
  Widget,
  WidgetUI,
  WidgetValueType,
} from "./types";

export function convertSecondsToTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = parseInt((seconds % 60).toString());

  return `${minutes}m ${remainingSeconds}s`;
}

const widgetValuesFormats: Record<string, (v: string | number) => string> = {
  TYPE_INTEGER: (val) => new Intl.NumberFormat("en-US").format(+val),
  TYPE_FLOAT: (val) => parseFloat(val as string).toFixed(2),
  TYPE_SECONDS: (val) => `${convertSecondsToTime(+val)}`,
  TYPE_STRING: (val) => (val === "N/A" ? "" : (val as string)),
};

export function formatWidgetsValues(value: WidgetValueType): string | number {
  return value[1] in widgetValuesFormats ? widgetValuesFormats[value[1]](value[0]) : value[0];
}

export function getCompareText(
  widgetLabel: string,
  dateRange: DateRange,
  chipSelected: string,
  value: WidgetValueType,
) {
  const isCustomDateSelected = chipSelected === DATE_CHIP_OPTIONS.CUSTOM;
  const days = isCustomDateSelected
    ? differenceInDays(parseISO(dateRange.endDate), parseISO(dateRange.startDate)) + 1 // +1 to include the end date
    : null;

  if (value[0] === "N/A") return `No comparison from previous ${isCustomDateSelected ? days + " days" : chipSelected} `;

  if (widgetLabel === "Most Popular Asset Type") {
    return isCustomDateSelected ? `views in last ${days} days` : `views last ${chipSelected}`;
  } else {
    return isCustomDateSelected ? `from previous ${days} days` : `from previous ${chipSelected}`;
  }
}

export function formatWidgetsData(widgets: Widget[], dateRange: DateRange, chipSelected: string): WidgetUI[] {
  return widgets.reduce<WidgetUI[]>((acc, { label, description, value, compare }) => {
    return [
      ...acc,
      {
        label,
        description,
        value: formatWidgetsValues(value), // Cast the value to WidgetValueType
        compare: {
          ...compare,
          value: formatWidgetsValues(compare.value), // Cast the value to WidgetValueType
          text: getCompareText(label, dateRange, chipSelected, compare.value),
        },
      },
    ];
  }, []);
}

export function prepareChartsData(
  chartData: string[][],
  title: string,
): { labels: string[]; values: string[]; title: string } {
  const { labels, values } = chartData.reduce<{ labels: string[]; values: string[] }>(
    (acc, [value, label]) => ({
      labels: [...acc.labels, label],
      values: [...acc.values, value],
    }),
    { labels: [], values: [] },
  );

  return {
    labels,
    values,
    title,
  };
}

export function formatExperienceTableData(
  data: ExperienceTableData[],
  includeOwners: boolean | undefined,
): FormattedRows[] {
  return data.map(({ name, owners, contacts, screenPageViews, screenPageViewsPerSession, averageSessionDuration }) => {
    return {
      name: name[0],
      ...(includeOwners && owners && { owners: owners[0] }),
      contacts: contacts[0],
      screenPageViews: formatWidgetsValues(screenPageViews),
      screenPageViewsPerSession: formatWidgetsValues(screenPageViewsPerSession),
      averageSessionDuration: formatWidgetsValues(averageSessionDuration),
    };
  });
}

export function formatAssetTableData(data: AssetTableData[]) {
  return data.reduce(
    (acc, { name, type, eventCount, averageSessionDuration }) => {
      return [
        ...acc,
        {
          name: name[0],
          type: type[0],
          eventCount: formatWidgetsValues(eventCount),
          averageSessionDuration: formatWidgetsValues(averageSessionDuration),
        },
      ];
    },
    [] as {
      name: string;
      type: string;
      eventCount: string | number;
      averageSessionDuration: string | number;
    }[],
  );
}

export function formatChannelTableData(data: ChannelData[]) {
  return data.reduce(
    (acc, { source, screenPageViews, screenPageViewsPerSession, averageSessionDuration }) => {
      return [
        ...acc,
        {
          source: source[0],
          screenPageViews: formatWidgetsValues(screenPageViews),
          screenPageViewsPerSession: formatWidgetsValues(screenPageViewsPerSession),
          averageSessionDuration: formatWidgetsValues(averageSessionDuration),
        },
      ];
    },
    [] as {
      source: string;
      screenPageViews: string | number;
      screenPageViewsPerSession: string | number;
      averageSessionDuration: string | number;
    }[],
  );
}

export function formatTotalValues(totalValues: Record<string, WidgetValueType>): Record<string, string | number> {
  const formattedTotalValues: Record<string, string | number> = {};
  for (const key in totalValues) {
    formattedTotalValues[key] = formatWidgetsValues(totalValues[key]);
  }

  return formattedTotalValues;
}

type ComparatorObject = Record<OrderBy, string[]>;

function descendingComparator(a: ComparatorObject, b: ComparatorObject, orderBy: OrderBy) {
  if (orderBy === ORDER_BY.OWNER || orderBy === ORDER_BY.CONTACT) {
    if (b[orderBy][0].length < a[orderBy][0].length) {
      return -1;
    }
    if (b[orderBy][0].length > a[orderBy][0].length) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy][0] < a[orderBy][0]) {
    return -1;
  }
  if (b[orderBy][0] > a[orderBy][0]) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: SortOrder, orderBy: OrderBy) {
  return order === SORT_ORDER.DESC
    ? (a: T, b: T) => descendingComparator(a as ComparatorObject, b as ComparatorObject, orderBy)
    : (a: T, b: T) => -descendingComparator(a as ComparatorObject, b as ComparatorObject, orderBy);
}

export const defaultWidget = {
  label: "",
  description: "",
  value: "0.00",
  compare: {
    sign: "",
    symbol: "",
    value: "0.00",
    text: "",
  },
};

type WidgetLabel = Record<string, string[]>;
export const widgetLabels: WidgetLabel = {
  channel: ["Total Views", "Avg Time Spent", "Avg Pages Viewed", "Sources"],
  asset: ["Total Views", "Avg Time Spent", "Asset Items", "Most Popular Asset Type"],
  experience: ["Total Views", "Avg Time Spent", "Avg Pages Viewed", "Experiences"],
};

export function downloadBase64PDF(base64String: string, fileName: string) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
