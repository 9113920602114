const { VITE_APP_DEV, VITE_APP_LOCAL, VITE_APP_PROD, VITE_APP_QA } = import.meta.env;

export function currentEnv() {
  if (VITE_APP_LOCAL === "true") {
    return "local";
  }
  if (VITE_APP_QA === "true") {
    return "qa";
  }
  if (VITE_APP_DEV === "true") {
    return "dev";
  }
  if (VITE_APP_PROD === "true") {
    return "production";
  }
  return "unknown";
}

export function reduxDevTools() {
  return VITE_APP_DEV === "true" || VITE_APP_QA === "true";
}

export function reduxLogger() {
  return false;
}

export function reduxPersist() {
  return (VITE_APP_DEV === "true" && VITE_APP_LOCAL !== "true") || VITE_APP_QA === "true" || VITE_APP_PROD === "true";
}
