import * as types from "./types";

const initialState = {};

export const slideToIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SLIDE_TO_INDEX: {
      return { ...state, slideToIndex: action.payload };
    }
    case types.RESET_SLIDE_TO_INDEX: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
