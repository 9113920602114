import { FontAwesomeIcon, type FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Button, type ButtonProps } from "@mui/material";
import type { Theme } from "@mui/material/styles";

type ButtonVariant = "filled" | "outlined" | "text" | "secondary" | "contextual";

interface LabeledIconButtonProps {
  icon?: FontAwesomeIconProps["icon"];
  label: string;
  onClick?: () => void;
  btnVariant: ButtonVariant;
  iconFontSize?: string;
}

const buttonStyles = {
  common: {
    fontFamily: "Inter",
    borderRadius: "24px",
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "none",
    padding: "8px 16px",
    height: "40px",
    lineHeight: "24px",
    gap: "8px",
    "& .MuiTouchRipple-root": {
      opacity: 0,
    },
    "& .MuiButton-startIcon": {
      margin: 0,
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  filled: {
    backgroundColor: "semanticPrimary.main",
    color: "#fff",
    "&:hover": {
      backgroundColor: "semanticPrimary.dark",
    },
    "&:focus-visible": {
      boxShadow: (theme: Theme) => `0 0 0 2px ${theme.palette.semanticSelected.main}!important`,
      backgroundColor: "semanticPrimary.main",
      "&:hover": {
        backgroundColor: "semanticPrimary.dark",
      },
    },
    "&:active:focus": {
      backgroundColor: "semanticPrimary.dark",
      boxShaddow: "none !important",
    },
    "&:disabled": {
      borderColor: "semanticSurface.light",
      backgroundColor: "semanticSurface.light",
      color: "semanticDisabledForeground.main",
    },
  },
  outlined: {
    border: "1px solid",
    borderColor: "semanticNeutral.main",
    color: "semanticPrimary.main",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor: "semanticNeutral.light",
      backgroundColor: "semanticSurface.light",
    },

    "&:focus-visible": {
      borderColor: "semanticSelected.main",
      boxShadow: (theme: Theme) => `0 0 0 1px ${theme.palette.semanticSelected.main}!important`,
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "semanticSurface.light",
      },
    },
    "&:active": {
      borderColor: "semanticNeutral.light",
      backgroundColor: "semanticSurface.light",
      boxShadow: (theme: Theme) => `0 0 0 1px ${theme.palette.semanticNeutral.light}!important`,
    },
    "&:disabled": {
      borderColor: "semanticDisabledForeground.main",
      backgroundColor: "semanticSurface.light",
      color: "semanticDisabledForeground.main",
    },
  },
  text: {
    border: "none",
    color: "semanticPrimary.main",
    "&:hover": {
      backgroundColor: "semanticSurface.light",
    },
    "&:focus-visible": {
      boxShadow: (theme: Theme) => `0 0 0 2px ${theme.palette.semanticSelected.main}!important`,
    },
    "&:disabled": {
      color: "semanticDisabledForeground.main",
    },
  },
  secondary: {
    backgroundColor: "semanticSurface.light",
    color: "semanticNeutral.light",
    "&:hover": {
      backgroundColor: "semanticSurface.light",
      color: "semanticNeutral.light",
    },
    "&:focus-visible": {
      backgroundColor: "semanticSurface.light",
      boxShadow: (theme: Theme) => `0 0 0 2px ${theme.palette.semanticSelected.main}!important`,
    },
    "&:active": {
      backgroundColor: "#fff",
      color: "semanticSelected.main",
    },
    "&:active:focus": {
      backgroundColor: "#fff",
    },
  },
  contextual: {
    border: "2px solid",
    borderColor: "semanticNeutral.main",
    backgroundColor: "#fff",
    color: "semanticNeutral.main",
    "&:hover": {
      backgroundColor: "semanticSurface.light",
    },
    "&:focus-visible": {
      borderColor: "semanticSelected.main",
      backgroundColor: "#fff",
      "&:hover:not(:active)": {
        backgroundColor: "semanticSurface.light",
      },
    },
    "&:active": {
      borderColor: "semanticSelected.main",
      backgroundColor: "#fff",
      color: "semanticSelected.main",
    },
    "&:disabled": {
      borderColor: "semanticDisabledForeground.main",
      backgroundColor: "semanticSurface.light",
      color: "semanticDisabledForeground.main",
    },
  },
};

export default function LabeledIconButton({
  icon,
  label,
  onClick,
  btnVariant,
  disabled,
  iconFontSize = "16px",
  ...buttonProps
}: LabeledIconButtonProps & ButtonProps) {
  const btnStyle = buttonStyles[btnVariant] || buttonStyles.filled;

  return (
    <Button
      startIcon={icon ? <FontAwesomeIcon icon={icon} style={{ fontSize: iconFontSize }} /> : null}
      sx={{ ...buttonStyles.common, ...btnStyle }}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      {label}
    </Button>
  );
}
