export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
} as const;

export type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const ORDER_BY = {
  AVG_SESSION_DURATION: "averageSessionDuration",
  URL: "fullPageUrl",
  VIEWS: "screenPageViews",
  VIEWS_SESSIONS: "screenPageViewsPerSession",
  NAME: "name",
  TYPE: "type",
  EVENT_COUNT: "eventCount",
  OWNER: "owners",
  CONTACT: "contacts",
  SOURCE: "source",
} as const;
export type OrderBy = (typeof ORDER_BY)[keyof typeof ORDER_BY];

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_DATE_UI_FORMAT = "MMM dd, yyyy";

export const DATE_CHIP_OPTIONS = {
  LAST_WEEK: "week",
  LAST_MONTH: "month",
  CUSTOM: "сustom",
} as const;
