import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import ErrorBoundary from "@/containers/ErrorBoundary/ErrorBoundary";
import LoadingSplash from "@/containers/LoadingComponent/LoadingSplash";
import ToastElement from "@/containers/Toast/ToastElement";
import "@/containers/Toast/toast.css";
import { useFlagsData } from "@/hooks";
import { selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";

const AsyncMainHeader = lazy(() => import("../VisualBuilder/containers/MainHeader"));

const VisualBuilderLayout = () => {
  const user = useThunkSelector(selectUser);

  useFlagsData({ userId: user?.id });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(44, 44, 44)",
        }}
        data-testid="AppLayout"
      >
        <AsyncMainHeader />
        <Box
          sx={{
            flexGrow: 1,
            zIndex: 1,
            display: "flex",
            width: "100%",
            flexDirection: "row",
            overflow: "inherit",
            backgroundColor: "rgb(44, 44, 44)",
            borderTop: "40px solid #333",
            height: "100%",
            position: "absolute",
          }}
        >
          <ErrorBoundary>
            <Suspense fallback={<LoadingSplash />}>
              <Box
                sx={{
                  overflow: "hidden",
                  height: "100%",
                  width: "100%",
                  overflowY: "auto",
                  "@media (max-width:576px)": {
                    overflow: "auto",
                  },
                }}
              >
                <Outlet />
              </Box>
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
      <ToastElement />
    </>
  );
};

export default VisualBuilderLayout;
