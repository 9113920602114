import * as types from "./types";

const initialState = {};

export const checkUrlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHECK_URLS: {
      const { data, status, err, link } = action.payload;
      return {
        ...state,
        [link]: {
          ...data,
          link,
          status,
          err,
        },
      };
    }
    case types.SET_CHECK_URLS_REQUEST_STATUS: {
      const { link, status } = action.payload;
      return {
        ...state,
        [link]: {
          ...(state[link] || []),
          link,
          status,
        },
      };
    }
    case types.RESET_CHECK_URLS: {
      const { link } = action.payload;

      if (link) {
        const newState = { ...state };
        delete newState[link];
        return { ...newState };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
