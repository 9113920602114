import axios, { AxiosError } from "axios";

axios.defaults.headers.common = {
  "app-url": window.location.origin,
};

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

[instance, api].forEach((axiosInstance) => {
  axiosInstance.interceptors.response.use(
    // any status code that lie within the range of 2xx cause this function to trigger
    null,
    // any status codes that falls outside the range of 2xx cause this function to trigger
    (error: unknown) => {
      if (error instanceof AxiosError && error.response?.status === 401 && window.location.pathname !== "/signin") {
        window.dispatchEvent(new Event("ACCESS_TOKEN_REMOVED"));
      }

      return Promise.reject(error);
    },
  );
});

export function setAuthorizationHeader(token: string) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default instance;
export { api };
