export const SET_ASSET_FILTERS = "SET_ASSET_FILTERS";

export interface AssetFiltersState {
  isActive: boolean;
  filters: {
    searchText: string;
    tags: Record<number, boolean>;
    types: Record<string, boolean>;
    categories: Record<number, boolean>;
  };
}

export type AssetFiltersPayload = Partial<AssetFiltersState["filters"]>;
