import { RESET_LOADING_STATUS, SET_LOADING_STATUS } from "./types";

export const loadingStatusReducer = (state = {}, action) => {
  if (action.type === SET_LOADING_STATUS) {
    {
      return {
        ...state,
        ...action.payload,
      };
    }
  } else if (action.type === RESET_LOADING_STATUS) {
    return {
      dataLoading: true,
      contentLoading: true,
      dashboardLoading: true,
      isLoading: false,
      isPublicView: false,
      splashTimeout: false,
    };
  } else {
    return state;
  }
};
