interface ThemeSource {
  isNightModeOn: boolean;
  source: "default";
}

interface ThemeSourceState {
  themeSource: ThemeSource;
}

export function themeSourceSelector(state: ThemeSourceState) {
  return state.themeSource;
}
