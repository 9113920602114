import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants/requestStatuses";

const initialState = {
  mediaItem: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const currentMediaItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_MEDIA_ITEM:
      return { ...state, ...action.payload };
    case types.REFRESH_CURRENT_MEDIA_ITEM:
      return { ...state, ...action.payload };
    case types.SET_CURRENT_MEDIA_ITEM_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_MEDIA_ITEM:
      return { ...initialState };
    default:
      return state;
  }
};
