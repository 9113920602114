import * as types from "./types";

const initialState = {};

export const uploadProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.payload.path]: action.payload.value,
      };
    case types.RESET_UPLOAD_PROGRESS:
      return { ...initialState };
    default:
      return state;
  }
};
