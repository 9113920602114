import { type AppState, Auth0Provider } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";

import AnalyticsTags from "@/containers/Root/AnalyticsTags";

const { VITE_APP_AUTH0_AUDIENCE, VITE_APP_AUTH0_CALLBACK_URL, VITE_APP_AUTH0_CLIENT_ID, VITE_APP_AUTH0_DOMAIN } =
  import.meta.env;

export default function Auth0ProviderLayout() {
  const navigate = useNavigate();

  const handleRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: VITE_APP_AUTH0_AUDIENCE,
        redirect_uri: VITE_APP_AUTH0_CALLBACK_URL,
      }}
      cacheLocation="localstorage"
      clientId={VITE_APP_AUTH0_CLIENT_ID}
      domain={VITE_APP_AUTH0_DOMAIN}
      onRedirectCallback={handleRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <AnalyticsTags />
      <Outlet />
    </Auth0Provider>
  );
}
