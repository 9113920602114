import { GET_VISUAL_BUILDER_DATA, RESET_VISUAL_BUILDER_DATA, UPDATE_VISUAL_BUILDER_DATA } from "./types";

const initialState = {};

export function visualBuilderDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VISUAL_BUILDER_DATA:
    case UPDATE_VISUAL_BUILDER_DATA: {
      const { key, ...values } = action.payload;

      return {
        ...state,
        [key]: {
          ...state[key],
          ...values,
        },
      };
    }
    case RESET_VISUAL_BUILDER_DATA: {
      const { key } = action.payload;

      if (key) {
        return {
          ...state,
          [key]: {},
        };
      }

      return {};
    }
    default:
      return state;
  }
}
