import type { Organization } from "@/Dashboard/pages/Accounts/types";
import { getNewList } from "@/utils/helper";

import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import type { Query } from "../queries/types";

interface ActionPayload {
  organizations?: Organization[];
  status?: string;
  err?: unknown;
  query: Query;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

const initialState = {
  organizations: [] as Organization[],
  status: REQUEST_STATUSES.IDLE,
  err: null as unknown,
};

export function organizationsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case types.GET_ORGANIZATIONS: {
      const { organizations, status, err, query } = action.payload;
      const newOrganizations = getNewList(query, state.organizations, organizations);
      return {
        ...state,
        organizations: newOrganizations,
        status,
        err,
        noResults: !newOrganizations.length,
      };
    }
    case types.SET_ORGANIZATIONS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_ORGANIZATIONS:
      return { ...initialState };
    default:
      return state;
  }
}
