export default function Alert({ htmlColor = "#FFFFFF" }) {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1207 1.62331C13.4989 0.925019 14.5011 0.925019 14.8793 1.62331L26.2003 22.5237C26.5613 23.19 26.0788 24 25.3211 24H2.67895C1.92118 24 1.43874 23.19 1.79965 22.5237L13.1207 1.62331Z"
        stroke={htmlColor || "#fff"}
        strokeWidth="1.5"
      />
      <line x1="14" y1="9" x2="14" y2="15.2105" stroke={htmlColor || "#fff"} strokeWidth="2" strokeLinecap="round" />
      <circle cx="14" cy="19" r="1" fill={htmlColor || "#fff"} stroke={htmlColor || "#fff"} />
    </svg>
  );
}
