import type { AssetCategory } from "@/Dashboard/pages/AssetLibrary/types";

import * as types from "./types";

interface ActionPayload {
  propertyId: number;
  assetCategories?: AssetCategory[];
  status?: string;
  err?: unknown;
  id?: number;
  data?: { name: string };
  category?: AssetCategory;
  ids: number[];
}

interface Action {
  type: string;
  payload: ActionPayload;
}

export function assetCategoriesReducer(
  state: Record<number, { assetCategories: AssetCategory[] }> = {},
  action: Action,
) {
  switch (action.type) {
    case types.GET_ASSET_CATEGORIES: {
      const { propertyId, assetCategories, status, err } = action.payload;
      return {
        ...state,
        [propertyId]: { assetCategories, status, err },
      };
    }
    case types.UPDATE_ASSET_CATEGORY: {
      const { propertyId, id, data } = action.payload;
      const category = state[propertyId].assetCategories.find((x) => x.id === id);
      state[propertyId].assetCategories = [...state[propertyId].assetCategories];
      category!.name = data?.name ?? "";
      return { ...state, [propertyId]: { ...state[propertyId] } };
    }
    case types.ADD_ASSET_CATEGORY: {
      const { propertyId, category } = action.payload;
      state[propertyId].assetCategories = [...state[propertyId].assetCategories, category!];
      return {
        ...state,
        [propertyId]: { ...state[propertyId] },
      };
    }
    case types.DELETE_ASSET_CATEGORIES: {
      const { propertyId, ids } = action.payload;
      state[propertyId].assetCategories = state[propertyId].assetCategories.filter((x) => !ids.includes(x.id));
      return {
        ...state,
        [propertyId]: { ...state[propertyId] },
      };
    }
    default:
      return state;
  }
}
