import { Outlet } from "react-router-dom";

import AnalyticsOverviewHeader from "@/Dashboard/components/AnalyticsOverview/Header/AnalyticsOverviewHeader";

const AnalyticsLayout = () => (
  <>
    <AnalyticsOverviewHeader />
    <Outlet />
  </>
);

export default AnalyticsLayout;
