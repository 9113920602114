import * as types from "./types";

const initialState = {};

export const visualBuilderValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VISUAL_BUILDER_VALUES: {
      const { keyId, key, value } = action.payload;
      const newValues = state[keyId] || {};
      newValues[key] = value;

      return { ...state, [keyId]: newValues };
    }
    case types.RESET_VISUAL_BUILDER_VALUES: {
      const { keyId } = action.payload;

      if (keyId) {
        return { ...state, [keyId]: {} };
      }

      return { ...initialState };
    }
    default:
      return state;
  }
};
