import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TAB_STATUSES } from "@/constants";
import { selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";
import { getVisualBuilderMode } from "@/utils/visualBuilder";

export interface UrlParams {
  root?: string;
  page?: string;
  propertyId?: string | number | null;
  mediaItemId?: string | number;
  experienceId?: string | number;
  tabStatus?: string;
  userId?: string | number;
  shareId?: string | null;
}

interface ParsedParams extends UrlParams {
  vbKey?: string;
  mode?: string;
}

export function parseParams(params: UrlParams): ParsedParams {
  const paths = window.location.pathname.split("/").filter((item) => !!item);
  const [root, , page] = paths;
  const newParams: Partial<ParsedParams> = { ...params, root, page: page || "" };

  if (newParams.propertyId === "create") {
    newParams.propertyId = null;
  }

  if (root === "properties" && newParams.propertyId) {
    if (newParams.experienceId) {
      const [, , , , tabStatus] = paths;
      newParams.tabStatus = tabStatus || TAB_STATUSES.PUBLIC;
    } else if (page === "all-assets-share") {
      newParams.tabStatus = TAB_STATUSES.PUBLIC_MEDIA_SHARE;
      newParams.shareId = new URLSearchParams(window.location.search).get("shareId");
    } else if (page === "single-asset-share") {
      newParams.tabStatus = TAB_STATUSES.PUBLIC_SINGLE_SHARE;
      newParams.shareId = new URLSearchParams(window.location.search).get("shareId");
    } else if (!page) {
      newParams.tabStatus = TAB_STATUSES.HOMEBASE;
    }
  }

  if (root === "profile") {
    newParams.propertyId = null;
  }

  if (newParams.tabStatus) {
    const { key, mode } = getVisualBuilderMode(newParams) as {
      key: string;
      mode: string;
    };
    newParams.vbKey = key;
    newParams.mode = mode;
  }

  if (newParams.propertyId && !Number.isNaN(+newParams.propertyId)) {
    newParams.propertyId = +newParams.propertyId;
  }

  if (newParams.mediaItemId && !Number.isNaN(+newParams.mediaItemId)) {
    newParams.mediaItemId = +newParams.mediaItemId;
  }

  if (newParams.experienceId && !Number.isNaN(+newParams.experienceId)) {
    newParams.experienceId = +newParams.experienceId;
  }

  return newParams;
}

export function useUrlParams() {
  const user = useThunkSelector(selectUser);
  const routerParams = useParams();

  const [params, setParams] = useState({ ...parseParams({ ...routerParams, userId: user?.id }) });

  useEffect(() => {
    setParams({ ...parseParams({ ...routerParams, userId: user?.id }) });
  }, [user?.id, window.location.pathname, JSON.stringify(routerParams)]);

  return params;
}
