import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  tags: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const tagsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TAGS: {
      const { propertyId, tags, status, err, query } = action.payload;
      const newTags = getNewList(query, state[propertyId].tags, tags);
      return {
        ...state,
        [propertyId]: {
          tags: newTags,
          status,
          err,
          noResults: !newTags.length,
        },
      };
    }
    case types.SET_TAGS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_TAGS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
