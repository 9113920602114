import { createSelector } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants";

interface Experience {
  id?: string | number;
  title?: string;
  locked?: boolean;
  createdBy?: {
    email: string;
    name: string;
    phones: string[];
    photoUrl: string;
  };
}

interface ExperienceState {
  experience: Experience;
  status: REQUEST_STATUSES;
  err: Error | null;
}

const initialState: ExperienceState = {
  experience: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

interface CurrentExperience {
  experience: Experience;
}

interface CurrentExperienceState {
  currentExperience: CurrentExperience;
}

export const currentExperienceSelector = createSelector(
  (state: CurrentExperienceState) => state.currentExperience,
  (currentExperience) => {
    return { ...initialState, ...currentExperience };
  },
);
