import {
  GET_CURRENT_SHARE,
  REFRESH_CURRENT_SHARE,
  RESET_CURRENT_SHARE,
  SET_CURRENT_SHARE_REQUEST_STATUS,
} from "./types";
import { REQUEST_STATUSES } from "../../../constants/requestStatuses";

const initialState = {
  share: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export function currentShareReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_SHARE:
    case REFRESH_CURRENT_SHARE: {
      const share = { ...state.share };
      const newShare = { ...action.payload.share };
      return { ...state, ...action.payload, share: { ...share, ...newShare } };
    }
    case SET_CURRENT_SHARE_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case RESET_CURRENT_SHARE:
      return { ...initialState };
    default:
      return state;
  }
}
