export const EXPERIENCE_STATUS = {
  DRAFT: "draft",
  TEMPLATE: "template",
  SHARED: "shared",
  SINGLE_SHARE: "single-share",
} as const;

export const EXPERIENCE_ACTIVITY = {
  UNOPENED: "unopened",
  OPENED: "opened",
} as const;

export const EXPERIENCE_PRIMARY_FILTER = {
  TYPE: "type",
  TAG: "tag",
} as const;

export const EXPERIENCE_OPTIONS = {
  RENAME: "rename",
  VIEW: "view",
  EDIT: "edit",
  CONVERT_TO_TEMPLATE: "convert_to_template",
  SHARE: "share",
  COPY_SHARE_LINK: "copy_share_link",
  DUPLICATE: "duplicate",
  FAVORITE: "favorite",
  DELETE: "delete",
  SAVE_AS_EXPERIENCE: "save_as_experience",
  CANCEL: "cancel",
  CREATE_FROM_TEMPLATE: "create_from_template",
  CREATE_FROM_EXPERIENCE: "create_from_experience",
  CREATE_EMBED_INTEGRATION: "create_embed_integration",
} as const;
