import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, Popover } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { compareAsc, format, parseISO, sub } from "date-fns";
import { type MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setSimpleValue } from "@/store/actions";

import DateSelectorChip from "./DateSelectorChip";
import { DATE_CHIP_OPTIONS, DEFAULT_DATE_FORMAT, DEFAULT_DATE_UI_FORMAT } from "../enums";

const styles = {
  dateRange: {
    display: "inline-flex",
    gap: "16px",
  },

  dateRangeContainer: {
    fontSize: "16px",
  },
  dateRangeHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
  },
  headerLabel: {
    flex: 1,
    textAlign: "center",
    background: "whitesmoke",
    padding: "8px",
  },
  error: {
    background: "#f4c7c3",
  },
  dateRangeCalendars: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    position: "absolute",
    right: "0",
    top: "0",
    borderRadius: "0",
    transition: "all 0.3s",
    "&:hover": {
      background: "#dedede",
      color: "black",
    },
  },
};

export default function AnalyticsDateSelectorChip() {
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [error, setError] = useState(false);
  const [calendarDateRange, setCalendarDateRange] = useState({ startDate: "", endDate: "" }); //yyyy-MM-dd
  const [isLastWeek, setIsLastWeek] = useState(true);
  const [isLastMonth, setIsLastMonth] = useState(false);

  // Initialize date range to past 7 days
  useEffect(() => {
    const today = new Date();

    const endDate = format(
      sub(today, {
        days: 1,
      }),
      DEFAULT_DATE_FORMAT,
    );

    const startDate = format(
      sub(today, {
        days: 7,
      }),
      DEFAULT_DATE_FORMAT,
    );

    setCalendarDateRange({ startDate, endDate });
    setDateRange({ startDate, endDate });
    dispatch(setSimpleValue("analyticsDateRange", { startDate, endDate }));
    dispatch(setSimpleValue("analyticsDateChip", DATE_CHIP_OPTIONS.LAST_WEEK));
  }, []);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setError(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-selector-popover" : undefined;

  const handleDateChangeRange = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
    const sDate = startDate ? format(startDate, DEFAULT_DATE_FORMAT) : calendarDateRange.startDate;
    const eDate = endDate ? format(endDate, DEFAULT_DATE_FORMAT) : calendarDateRange.endDate;

    // Compare the two dates and return 1 if the first date is after the second,
    // -1 if the first date is before the second or
    // 0 if dates are equal.
    const compare = compareAsc(parseISO(eDate), parseISO(sDate));
    if (compare > 0 || compare === 0) {
      // will fetch analytics data for the selected date range
      setDateRange({ startDate: sDate, endDate: eDate });
      dispatch(setSimpleValue("analyticsDateChip", DATE_CHIP_OPTIONS.CUSTOM));
      dispatch(setSimpleValue("analyticsDateRange", { startDate: sDate, endDate: eDate }));
      setError(false);
    } else {
      setError(true);
    }

    setCalendarDateRange({ startDate: sDate, endDate: eDate });
    setIsLastWeek(false);
    setIsLastMonth(false);
  };

  const handleDateRangeSelection = (type: string) => {
    let startDate, endDate;

    switch (type) {
      case DATE_CHIP_OPTIONS.LAST_WEEK:
        setIsLastWeek(true);
        setIsLastMonth(false);
        dispatch(setSimpleValue("analyticsDateChip", DATE_CHIP_OPTIONS.LAST_WEEK));

        startDate = format(sub(new Date(), { days: 7 }), DEFAULT_DATE_FORMAT);
        endDate = format(sub(new Date(), { days: 1 }), DEFAULT_DATE_FORMAT);
        break;
      case DATE_CHIP_OPTIONS.LAST_MONTH:
        setIsLastMonth(true);
        setIsLastWeek(false);
        dispatch(setSimpleValue("analyticsDateChip", DATE_CHIP_OPTIONS.LAST_MONTH));

        startDate = format(sub(new Date(), { months: 1 }), DEFAULT_DATE_FORMAT);
        endDate = format(sub(new Date(), { days: 1 }), DEFAULT_DATE_FORMAT);
        break;
      default:
        return;
    }

    setCalendarDateRange({ startDate, endDate });
    setDateRange({ startDate, endDate });
    dispatch(setSimpleValue("analyticsDateRange", { startDate, endDate }));
  };

  const onHandleDateChipSelect = (type: string) => () => {
    handleDateRangeSelection(type);
  };

  // displaying only the valid date in the button
  const startDateUI = dateRange.startDate.length && format(parseISO(dateRange.startDate), DEFAULT_DATE_UI_FORMAT);
  const endDateUI = dateRange.endDate.length && format(parseISO(dateRange.endDate), DEFAULT_DATE_UI_FORMAT);
  const label = `Date Range: ${startDateUI} - ${endDateUI}`;

  return (
    <Box sx={styles.dateRange}>
      <DateSelectorChip label={label} onClick={handleClick} isActive={true} />
      <DateSelectorChip
        label={"Last Week"}
        onClick={onHandleDateChipSelect(DATE_CHIP_OPTIONS.LAST_WEEK)}
        isActive={isLastWeek}
      />
      <DateSelectorChip
        label={"Last Month"}
        onClick={onHandleDateChipSelect(DATE_CHIP_OPTIONS.LAST_MONTH)}
        isActive={isLastMonth}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <IconButton sx={styles.closeIcon} onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Box sx={styles.dateRangeContainer}>
          <Box sx={styles.dateRangeHeader}>
            {error ? (
              <Box component="span" sx={[styles.headerLabel, styles.error]}>
                End date is earlier than start date
              </Box>
            ) : (
              <>
                <Box component="span" sx={styles.headerLabel}>
                  Start Date
                </Box>
                <Box component="span" sx={styles.headerLabel}>
                  End Date
                </Box>
              </>
            )}
          </Box>
          <Box sx={styles.dateRangeCalendars}>
            <DateCalendar
              value={parseISO(calendarDateRange.startDate)}
              onChange={(newValue: Date) => handleDateChangeRange({ startDate: newValue })}
              data-testid="start-date-calendar"
              maxDate={new Date()}
            />
            <DateCalendar
              value={parseISO(calendarDateRange.endDate)}
              onChange={(newValue: Date) => handleDateChangeRange({ endDate: newValue })}
              data-testid="end-date-calendar"
              maxDate={new Date()}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
