// NOTE: we want to keep this as js file as this loader was copy/pasted, otherwise we would need to add types
// https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application

export default function loadPendo(apiKey) {
  (function (apiKey) {
    (function (p, e, n, d) {
      let v;
      let w;
      let x;
      let y;
      let z;
      let o = (p[d] = p[d] || {});
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  })(apiKey);
}
