import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { ENTITY } from "../constants";
import { getFlags } from "../store/actions";

interface UseFlagsDataProps {
  userId: number | undefined;
}

export function useFlagsData(props: UseFlagsDataProps) {
  const { userId } = props;

  const dispatch = useDispatch();

  // NOTE: the NodeJS.Timeout type is not quite right as we are running this code in the browser and not in node.js
  // in the browser, setTimeout returns a number, in node.js setTimeout returns NodeJS.Timeout
  // ReturnType<typeof setTimeout> satisfies both environments
  // https://stackoverflow.com/questions/45802988/typescript-use-correct-version-of-settimeout-node-vs-window
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const fetchTags = () => {
    if (!userId) {
      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      // TODO FIXME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getFlags());
    }, 300);
  };

  useEffect(() => {
    fetchTags();
  }, [userId]);

  useEffect(() => {
    const listener = () => fetchTags();

    const entities: [ENTITY] = [ENTITY.FLAG];

    entities.forEach((entity) => document.addEventListener(entity, listener));
    return () => entities.forEach((entity) => document.removeEventListener(entity, listener));
  }, [userId]);
}
