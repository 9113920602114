import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  branding: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BRANDING: {
      const { propertyId, ...values } = action.payload;
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          ...values,
        },
      };
    }
    case types.SET_BRANDING_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          status,
        },
      };
    }
    case types.RESET_BRANDING: {
      const { propertyId } = action.payload;

      if (propertyId) {
        return {
          ...state,
          [propertyId]: initialState,
        };
      }

      return {};
    }
    default:
      return state;
  }
};
