import { Provider } from "react-redux";

import appConfig from "@/config/config";
import Root from "@/containers/Root";
import store from "@/store";

export default function App() {
  return (
    <Provider store={store}>
      <Root appConfig={appConfig} />
    </Provider>
  );
}
