export default function NoPageIcon() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 7V43H13L13 25V16.4407L22.3833 7L40 7Z"
        stroke="#8F0339"
        strokeLinecap="square"
      />
      <path
        d="M19 22L19.8787 22.8787C21.0503 24.0503 22.9497 24.0503 24.1213 22.8787L25 22"
        stroke="#8F0339"
        strokeLinecap="round"
      />
      <path
        d="M29 22L29.8787 22.8787C31.0503 24.0503 32.9497 24.0503 34.1213 22.8787L35 22"
        stroke="#8F0339"
        strokeLinecap="round"
      />
      <path
        d="M35 31L32.9962 29.6641C31.4846 28.6564 29.5154 28.6564 28.0038 29.6641L26 31"
        stroke="#8F0339"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 40C19.3137 40 22 37.3137 22 34C22 30.6863 19.3137 28 16 28C12.6863 28 10 30.6863 10 34C10 37.3137 12.6863 40 16 40Z"
        stroke="#8F0339"
      />
      <path
        d="M21.3536 38.6464C21.1583 38.4512 20.8417 38.4512 20.6464 38.6464C20.4512 38.8417 20.4512 39.1583 20.6464 39.3536L21.3536 38.6464ZM27.6464 46.3536C27.8417 46.5488 28.1583 46.5488 28.3536 46.3536C28.5488 46.1583 28.5488 45.8417 28.3536 45.6464L27.6464 46.3536ZM20.6464 39.3536L27.6464 46.3536L28.3536 45.6464L21.3536 38.6464L20.6464 39.3536Z"
        fill="#8F0339"
      />
      <path d="M22 7V16H13" stroke="#8F0339" strokeLinecap="round" />
    </svg>
  );
}
