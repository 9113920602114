import * as types from "./types";

const initialState = {};

export const searchOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_OPTIONS: {
      const { key, ...values } = action.payload;
      return {
        ...state,
        [key]: {
          ...(state[key] || {}),
          ...values,
        },
      };
    }
    case types.SET_SEARCH_OPTIONS_REQUEST_STATUS: {
      const { key, status } = action.payload;
      return {
        ...state,
        [key]: {
          ...(state[key] || {}),
          status,
        },
      };
    }
    case types.RESET_SEARCH_OPTIONS: {
      const { key } = action.payload;

      if (key) {
        return {
          ...state,
          [key]: initialState,
        };
      }

      return {};
    }
    default:
      return state;
  }
};
