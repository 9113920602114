interface ContentType {
  value: string;
}

interface MediaItem {
  contentType: ContentType;
  name: string;
  id: number;
  link: string;
}

interface CurrentMediaItem {
  mediaItem: MediaItem;
}

interface CurrentMediaItemState {
  currentMediaItem: CurrentMediaItem;
}

export function currentMediaItemSelector(state: CurrentMediaItemState) {
  return state.currentMediaItem;
}
