export default function LoadingSplash() {
  return (
    <div
      data-testid="loading-splash"
      style={{
        background: "#fff",
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        width: "100%",
        zIndex: 1204,
      }}
    >
      <div
        className="container"
        style={{
          alignItems: "center",
          display: "flex",
          flexFlow: "column wrap",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <img
          alt="Logo"
          className="loader"
          src="https://truetour.app/static/media/VisitingMediaNewLogo.4e789e37.svg"
          style={{
            height: "80px",
            maxWidth: "300px",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
