export const TAB_STATUSES = {
  HOMEBASE: "homebase",
  COLLECT: "collect",
  CUSTOMIZE: "customize",
  SHARE: "share",
  PUBLIC: "public",
  PUBLIC_MEDIA_SHARE: "public_media_share",
  PUBLIC_SINGLE_SHARE: "public_single_share",
} as const;

export type TAB_STATUSES = (typeof TAB_STATUSES)[keyof typeof TAB_STATUSES];
