import { GET_FLAGS, SET_FLAGS_REQUEST_STATUS } from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { FlagService } from "../../../services";

export const getFlags = (query) => {
  return async (dispatch) => {
    dispatch({
      payload: { status: REQUEST_STATUSES.LOADING },
      type: SET_FLAGS_REQUEST_STATUS,
    });

    const { resp, status, err } = await FlagService.getFlags(query);

    dispatch({
      payload: { flags: resp?.data || [], status, err, query },
      type: GET_FLAGS,
    });
  };
};
