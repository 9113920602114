import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { Suspense, lazy, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import DoesNotExist from "@/Dashboard/pages/DoesNotExist/DoesNotExist";
import ErrorBoundary from "@/containers/ErrorBoundary/ErrorBoundary";
import LoadingSplash from "@/containers/LoadingComponent/LoadingSplash";
import ToastElement from "@/containers/Toast/ToastElement";
import { useFlagsData } from "@/hooks";
import { PUBLIC_ROUTE } from "@/routes/constants";
import { api } from "@/services/instance";
import store from "@/store";
import { getAuthorization, selectAuthStatus, selectUser, setStatus, setUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";
import { getAccessToken } from "@/utils/auth";

const AsyncMainHeader = lazy(() => import("../VisualBuilder/containers/MainHeader"));
const AsyncSidebar = lazy(() => import("../Dashboard/components/Sidebar/Sidebar"));

export const dashboardLoader = async ({ params }: { params: Record<string, unknown> }) => {
  const accessToken = getAccessToken();
  const propertyId = params.propertyId as number;

  if (!accessToken) {
    return null;
  }

  try {
    await store.dispatch(getAuthorization({ authenticationToken: accessToken, propertyId }));
    const response = await api(`/api/v2/users/${store.getState().auth?.user.id}`);

    await store.dispatch(setUser(response.data));
    await store.dispatch(setStatus("success"));

    return store.getState().auth?.user;
  } catch (error) {
    console.error(error);
    await store.dispatch(setStatus("error"));

    return null;
  }
};

const DashboardLayout = () => {
  const user = useThunkSelector(selectUser);
  const authStatus = useThunkSelector(selectAuthStatus);
  const { isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  useFlagsData({ userId: user?.id });

  useEffect(() => {
    if ((!isLoading && !isAuthenticated) || error) {
      navigate(PUBLIC_ROUTE.SIGN_IN);
    }
  }, [isLoading, isAuthenticated, error]);

  if (isAuthenticated && authStatus === "error") {
    return <DoesNotExist returnPrev={false} message="Oops, You need to be invited first" />;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSplash />}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
          }}
          data-testid="AppLayout"
        >
          <AsyncMainHeader />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              zIndex: 1,
              display: "flex",
              width: "100%",
              flexDirection: "row",
              overflow: "inherit",
              backgroundColor: "#fff",
              borderTop: "40px solid #333",
              height: "100%",
              position: "absolute",
            }}
          >
            <AsyncSidebar />
            <ErrorBoundary>
              <Suspense>
                <Box
                  sx={{
                    overflow: "hidden",
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                    "@media (max-width:576px)": {
                      overflow: "auto",
                    },
                  }}
                >
                  <Outlet />
                </Box>
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Box>
        <ToastElement />
      </Suspense>
    </ErrorBoundary>
  );
};

export default DashboardLayout;
