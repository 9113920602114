import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  experiences: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const experiencesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_EXPERIENCES: {
      const { propertyId, experienceTab, experiences, count, status, err, query } = action.payload;
      const key = `${propertyId}#${experienceTab}`;
      const newExperiences = getNewList(query, state[key].experiences, experiences);
      return {
        ...state,
        [key]: {
          experiences: newExperiences,
          count,
          status,
          err,
          noResults: !newExperiences.length,
        },
      };
    }
    case types.SET_EXPERIENCES_REQUEST_STATUS: {
      const { propertyId, experienceTab, status } = action.payload;
      const key = `${propertyId}#${experienceTab}`;
      return { ...state, [key]: { ...(state[key] || {}), status } };
    }
    case types.RESET_EXPERIENCES: {
      const { propertyId, experienceTab } = action.payload;
      if (propertyId && experienceTab) {
        const key = `${propertyId}#${experienceTab}`;
        return { ...state, [key]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
