import { Button, type ClassNameMap } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Component, type ErrorInfo, type ReactNode } from "react";

import { Error } from "../../icons";

const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100% - 240px)",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "240px",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontSize: 36,
    textAlign: "center",
  },
} as const;

interface ErrorBoundaryProps {
  readonly children: ReactNode;
  readonly classes: ClassNameMap;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  readonly state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    console.error("getDerivedStateFromError", error);

    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
  }

  render() {
    const { children, classes } = this.props;

    if (this.state.hasError) {
      return (
        <div data-testid="ErrorBoundary" className={classes.container}>
          <div className={classes.box}>
            <Error htmlColor="#8f0339" height={70} width={70} />
            <h2 className={classes.heading}>Sorry! We have encountered an error!</h2>
            <Button variant="contained" color="primary" href="/">
              Return to Dashboard
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
