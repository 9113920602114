import { createSelector } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants";
import type { State, UnivCategories } from "@/store/types";

const initialState: UnivCategories = {
  univCategories: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const univCategoriesSelector = createSelector(
  (state: State) => state.univCategories,
  (univCategories) => {
    return { ...initialState, ...univCategories };
  },
);
