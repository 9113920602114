import { lazy } from "react";
import { Outlet } from "react-router-dom";

const AsyncFullscreenDropzone = lazy(() => import("../containers/FullscreenDropzone/FullscreenDropzone"));

const DropZoneLayout = () => (
  <AsyncFullscreenDropzone>
    <Outlet />
  </AsyncFullscreenDropzone>
);

export default DropZoneLayout;
