import { createSelector } from "@reduxjs/toolkit";

interface State {
  assetSelection: {
    assetCategorySelection: Record<number, boolean>;
    assetCategoryExpandedSelection: Record<number, boolean>;
    assetItemSelection: Record<number, boolean>;
  };
}

const initialState = {
  assetCategorySelection: {},
  assetItemSelection: {},
  assetCategoryExpandedSelection: {},
};

export const assetSelectionSelector = createSelector(
  (state: State) => state.assetSelection,
  (data) => ({ ...initialState, ...data }),
);
