import { isFunction, isUndefined } from "lodash";
import { type PropsWithChildren, useEffect, useMemo, useState } from "react";

import type { PropertyTier } from "@/Dashboard/pages/AssetLibrary/types";
import type { PropertyInterface } from "@/Dashboard/pages/PropertySettings/types";
import { selectAuthStatus, selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";
import type { User } from "@/store/v2/currentUser/selectors";

export interface AccessControlProps {
  allowedPermissions?: string[];
  allowedTiers?: PropertyTier[];
  renderNoAccess?: () => JSX.Element | null;
  accessCheck?: (user: User, extraData: unknown) => boolean;
  extraAccessData?: unknown;
}

const AccessControl = ({
  allowedPermissions = [],
  allowedTiers = [],
  children,
  renderNoAccess = () => null,
  accessCheck,
  extraAccessData,
}: PropsWithChildren<AccessControlProps>) => {
  const results = [];
  const [isReady, setIsReady] = useState(false);
  const authStatus = useThunkSelector(selectAuthStatus);
  const user = useThunkSelector(selectUser);
  const property = useThunkSelector((state) => state.currentProperty?.property as PropertyInterface);
  const permissions = useMemo(() => user?.permissions, [user]);
  const JSONpermissions = JSON.stringify(permissions);

  useEffect(() => {
    if (authStatus === "success") {
      setIsReady(true);
    }
  }, [authStatus]);

  if (isReady) {
    if (isFunction(accessCheck) && !isUndefined(user)) {
      results.push(accessCheck(user, extraAccessData));
    }

    if (allowedPermissions.length) {
      results.push(allowedPermissions.every((permission) => JSONpermissions.includes(permission)));
    }

    if (allowedTiers.length && property?.tier) {
      results.push(allowedTiers.includes(property.tier.title as PropertyTier));
    }

    return results.includes(false) ? renderNoAccess() : children;
  }

  return <></>;
};

export default AccessControl;
