/**
 * Returns a random number in the range of the provided arguments;
 * defaulting 0-1 as a replacement of Math.random()
 *
 * @param low - the lowest possible value for the result
 * @param high - the highest possible value for the result
 * @returns - the randomly chosen value
 */
export function random(low = 0, high = 1) {
  const rand = crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;

  return rand * (high - low) + low;
}
