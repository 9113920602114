import { GET_CURRENT_USER, REFRESH_CURRENT_USER, RESET_CURRENT_USER, SET_CURRENT_USER_REQUEST_STATUS } from "./types";
import { REQUEST_STATUSES } from "../../../constants/requestStatuses";

const initialState = {
  err: null,
  status: REQUEST_STATUSES.IDLE,
  user: {},
};

export function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return { ...state, ...action.payload };
    case REFRESH_CURRENT_USER:
      return { ...state, ...action.payload };
    case SET_CURRENT_USER_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case RESET_CURRENT_USER:
      return { ...initialState };
    default:
      return state;
  }
}
