import { setAuthorizationHeader } from "@/services/instance";

export function getAccessToken() {
  return JSON.parse(
    localStorage.getItem(
      `@@auth0spajs@@::${import.meta.env.VITE_APP_AUTH0_CLIENT_ID}::${import.meta.env.VITE_APP_AUTH0_AUDIENCE}::openid profile email offline_access`,
    ),
  )?.body.access_token;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveAuthorizationToken = (_store) => (next) => (action) => {
  if (action.type === "auth/getAuthorization/fulfilled") {
    setAuthorizationHeader(action.payload);
  }

  return next(action);
};
