import type { Asset as LibraryAsset } from "@/Dashboard/pages/AssetLibrary/types";
import type { Asset as StoreAsset, State } from "@/store/types";

import * as types from "./types";

interface ActionPayload {
  propertyId: number;
  categoryId: number;
  assets: LibraryAsset[];
  asset: LibraryAsset;
  filtered: LibraryAsset[];
  status: string;
  err: unknown;
  assetIds: number[];
}

interface Action {
  type: string;
  payload: ActionPayload;
}

// Utility function to ensure Asset meets store requirements
function ensureValidAsset(asset: LibraryAsset): StoreAsset {
  return {
    ...asset,
    assetCategoryId: asset.assetCategoryId ?? 0, // Provide a default if undefined
    mediaItemMembers: asset.mediaItemMembers || [],
  } as StoreAsset;
}

// Utility function to convert array of assets
function ensureValidAssets(assets: LibraryAsset[]): StoreAsset[] {
  return assets.map(ensureValidAsset);
}

export function assetsReducer(state: State["assets"] = {}, action: Action) {
  switch (action.type) {
    case types.GET_ASSETS: {
      const { propertyId, assets, filtered = [], status, err } = action.payload;
      return {
        ...state,
        [propertyId]: {
          assets: ensureValidAssets(assets),
          filtered: ensureValidAssets(filtered),
          status,
          err,
        },
      };
    }
    case types.SET_FILTERED_ASSETS: {
      const { propertyId, filtered } = action.payload;
      if (!state[propertyId]) {
        return {
          ...state,
          [propertyId]: {
            assets: [],
            filtered: ensureValidAssets(filtered),
            status: "success",
            err: null,
          },
        };
      }
      return {
        ...state,
        [propertyId]: { ...state[propertyId], filtered: ensureValidAssets(filtered) },
      };
    }
    case types.SET_ASSETS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      if (!state[propertyId]) {
        return {
          ...state,
          [propertyId]: {
            assets: [],
            filtered: [],
            status,
            err: null,
          },
        };
      }
      return {
        ...state,
        [propertyId]: { ...state[propertyId], status },
      };
    }
    case types.UPDATE_ASSET: {
      const { propertyId, asset } = action.payload;
      if (!state[propertyId]) return state;

      const validAsset = ensureValidAsset(asset);
      const index = state[propertyId].assets.findIndex((x) => x.id === validAsset.id);
      if (index === -1) return state;

      const updatedAssets = [...state[propertyId].assets];
      updatedAssets[index] = validAsset;

      return {
        ...state,
        [propertyId]: { ...state[propertyId], assets: updatedAssets },
      };
    }
    case types.DELETE_ASSETS: {
      const { propertyId, assetIds } = action.payload;
      if (!state[propertyId]) return state;

      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          assets: state[propertyId].assets.filter((asset) => !assetIds.includes(asset.id)),
        },
      };
    }
    case types.CREATE_ASSET: {
      const { propertyId, asset } = action.payload;
      if (!state[propertyId]) {
        return {
          ...state,
          [propertyId]: {
            assets: [ensureValidAsset(asset)],
            filtered: [],
            status: "success",
            err: null,
          },
        };
      }
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          assets: [...state[propertyId].assets, ensureValidAsset(asset)],
        },
      };
    }
    case types.RESET_ASSETS: {
      const newState = { ...state };
      delete newState[action.payload.propertyId];
      return newState;
    }
    default:
      return state;
  }
}
