import { REQUEST_STATUSES } from "../constants";
import instance from "./instance";
import { getErrorResponse } from "../utils/helper";

function buildVisualizationUrl(basePath, propertyId, experienceId, mediaItemId) {
  let url = `${basePath}/${propertyId}`;

  if (mediaItemId) {
    url += `/mediaItem/${mediaItemId}`;
  }

  if (experienceId) {
    url += `/${experienceId}`;
  }

  return url;
}

export class PublicVisualizationService {
  static async fetchData(basePath, propertyId, experienceId, query, mediaItemId) {
    try {
      const url = buildVisualizationUrl(basePath, propertyId, experienceId, mediaItemId);

      const resp = await instance({
        method: "get",
        url,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getSearchOptions(propertyId, experienceId, query) {
    try {
      let url = `/api/v2/public-visualizations/search/options/${propertyId}`;

      if (experienceId) {
        url += `/${experienceId}`;
      }

      const resp = await instance({
        method: "get",
        url,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: {},
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getContentTypes(propertyId, experienceId, query, mediaItemId) {
    return this.fetchData("/api/v2/public-visualizations/content-types", propertyId, experienceId, query, mediaItemId);
  }

  static async getTags(propertyId, experienceId, query, mediaItemId) {
    return this.fetchData("/api/v2/public-visualizations/tags", propertyId, experienceId, query, mediaItemId);
  }

  static async getMediaItems(propertyId, experienceId, query, mediaItemId) {
    return this.fetchData("/api/v2/public-visualizations/media-items", propertyId, experienceId, query, mediaItemId);
  }

  static async getPublicProperty(propertyId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/property/${propertyId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicExperience(propertyId, experienceId, query) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/experience/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicShare(propertyId, experienceId, shareId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/share/${propertyId}/${experienceId}/${shareId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicMediaShare(propertyId, shareId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/media-share/${propertyId}/${shareId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicBranding(propertyId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/branding/${propertyId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicExperienceOwner(propertyId, experienceId, shareId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/experience-owner/${propertyId}/${experienceId}`,
        params: { shareId },
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPublicMediaShareOwner(propertyId, shareId) {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/public/media-share/${propertyId}/${shareId}/owner`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getFlags() {
    try {
      const resp = await instance({
        method: "get",
        url: `/api/v2/public-visualizations/flags`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
