import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

export class AnalyticsService {
  static async getReport(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getReportUrl(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/csv`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsOverview(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/overview`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsExperienceDetailedReport(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/experience`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsAssetDetailedReport(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/asset`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsChannelDetailedReport(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/channel`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsOverviewPdf(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/overview/pdf`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err),
        resp: null,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsExperienceDetailedReportPdf(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/experience/pdf`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsAssetDetailedReportPdf(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/asset/pdf`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err),
        resp: null,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsChannelDetailedReportPdf(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/channel/pdf`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsExperienceDetailedReportCsv(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/experience/csv`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err),
        resp: null,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsAssetDetailedReportCsv(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/asset/csv`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAnalyticsChannelDetailedReportCsv(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/analytics/${propertyId}/channel/csv`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
