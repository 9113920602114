import { isFunction, isUndefined } from "lodash";
import { useMemo } from "react";

import type { PropertyTier } from "@/Dashboard/pages/AssetLibrary/types";
import type { PropertyInterface } from "@/Dashboard/pages/PropertySettings/types";
import type { AccessControlProps } from "@/components/AccessControl/AccessControl";
import { selectAuthStatus, selectUser } from "@/store/auth/auth.slice";
import { useThunkSelector } from "@/store/hooks";

export const useAccessControl = () => {
  const authStatus = useThunkSelector(selectAuthStatus);
  const user = useThunkSelector(selectUser);
  const property = useThunkSelector((state) => state.currentProperty?.property as PropertyInterface);
  const permissions = useMemo(() => user?.permissions, [user]);
  const JSONpermissions = JSON.stringify(permissions);

  const canAccess = (
    {
      allowedPermissions = [],
      allowedTiers = [],
      accessCheck,
      extraAccessData,
    }: Omit<AccessControlProps, "renderNoAccess"> = {
      allowedPermissions: [],
      allowedTiers: [],
    },
  ) => {
    const results = [];
    const isAuthorized = authStatus === "success";

    if (isAuthorized) {
      if (isFunction(accessCheck) && !isUndefined(user)) {
        results.push(accessCheck(user, extraAccessData));
      }

      if (allowedPermissions.length) {
        results.push(allowedPermissions.every((permission) => JSONpermissions.includes(permission)));
      }

      if (allowedTiers.length && property && property.tier) {
        results.push(allowedTiers.includes(property.tier.title as PropertyTier));
      }

      return !results.includes(false);
    }
  };

  return useMemo(
    () => ({
      canAccess,
    }),
    [canAccess],
  );
};
