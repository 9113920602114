import type { AxiosError } from "axios";

import { REQUEST_STATUSES } from "@/constants";
import type { Query } from "@/store/v2/queries/types";

export const getNewList = <T>(query: Query, list: T[] = [], newList: T[] = []) =>
  !query.offset ? newList : [...list, ...newList];

const allowedStatuses: REQUEST_STATUSES[] = [REQUEST_STATUSES.IDLE, REQUEST_STATUSES.FAILED];

export const isRequestAllowed = (status: REQUEST_STATUSES) => allowedStatuses.indexOf(status) > -1;

export function getDocumentName(name: string, count: number) {
  let str = `${name === "media-items" ? "content items" : name}`;
  if (count < 2) str = str.slice(0, str.length - 1);
  return str;
}

export function getErrorResponse<T, D>(err: AxiosError<T, D>) {
  if (err.response) {
    if (typeof err.response.data === "string") return { message: err.response.data, status: err.response.status };

    return { ...(err.response.data || {}), status: err.response.status };
  }

  return { message: err.message || err, status: err.status || 400 };
}
