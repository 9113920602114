import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  contactNotes: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const contactNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTACT_NOTES: {
      const { contactNotes, status, err, query } = action.payload;
      const newContactNotes = getNewList(query, state.contactNotes, contactNotes);
      return {
        ...state,
        contactNotes: newContactNotes,
        status,
        err,
      };
    }
    case types.SET_CONTACT_NOTES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CONTACT_NOTES:
      return { ...initialState };
    default:
      return state;
  }
};
