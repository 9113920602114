import { Helmet } from "react-helmet";

interface OpenGraphTagsProps {
  readonly image?: string;
  readonly title?: string;
}

export default function OpenGraphTags(props: OpenGraphTagsProps) {
  const { origin: domain, href: url } = location;

  const description =
    "Click here to view this amazing place in a completely new way. SalesHub provides you with an immersive virtual experience to share through the web.";

  const {
    image = new URL("/vm-link-preview-image.svg", domain).toString(),
    title = "SalesHub - Immersive Hospitality Sales Software",
  } = props;

  return (
    <Helmet>
      {/*  Facebook Meta Tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="Visiting Media" />
      <meta name="application-name" content="True Tour" />
    </Helmet>
  );
}
