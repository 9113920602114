import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedNewExperience: false,
  clickedNewIcon: false,
  dashboardStatus: true,
  experienceCollectionName: "",
  experienceCollectionPath: "",
  notificationStatus: false,
  propertyCollectionPath: "",
  savedExperienceURL: "",
  selectedProperty: "",
  tabStatus: false,
};

// TODO: Fix the data types here, tabStatus has been used as a string all over the app T_T
const MainHeaderDataSlice = createSlice({
  initialState,
  name: "mainHeaderData",
  reducers: {
    changeTabStatus: (state, { payload }: PayloadAction<{ tab: boolean | string }>) => {
      const { tab } = payload;
      state.tabStatus = !!tab;
    },
    setDashboard: (state, { payload }: PayloadAction<{ dashboardStatus: boolean }>) => {
      const { dashboardStatus } = payload;
      state.dashboardStatus = dashboardStatus;
    },
    setNotification: (state, { payload }: PayloadAction<{ notificationStatus: boolean }>) => {
      const { notificationStatus } = payload;
      state.notificationStatus = notificationStatus;
    },
  },
});

export const { changeTabStatus, setDashboard, setNotification } = MainHeaderDataSlice.actions;

export default MainHeaderDataSlice.reducer;

interface MainHeaderDataState {
  mainHeaderData: typeof initialState;
}

export const mainHeaderDataSliceSelector = createSelector(
  (state: MainHeaderDataState) => state.mainHeaderData,
  (mainHeaderData) => {
    return mainHeaderData || {};
  },
);
