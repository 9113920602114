import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  coaches: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const coachesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COACHES: {
      const { coaches, status, err, query } = action.payload;
      const newCoaches = getNewList(query, state.coaches, coaches);
      return {
        ...state,
        coaches: newCoaches,
        status,
        err,
        noResults: !newCoaches.length,
      };
    }
    case types.SET_COACHES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_COACHES:
      return { ...initialState };
    default:
      return state;
  }
};
