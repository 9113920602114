import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import appReducers from "./reducers";
import config from "../config/config";
import { currentEnv, reduxDevTools, reduxLogger, reduxPersist } from "../config/envSettings";
import { saveAuthorizationToken } from "../utils/auth";

const persistConfig = {
  blacklist: [
    "auth",
    "form",
    "mainHeaderData",
    "messaging",
    "simpleValues",
    "visualBuilderData",
    // v2
    "checkUrls",
    "coaches",
    "contactNotes",
    "contacts",
    "contentElements",
    "contentTypes",
    "currentElement",
    "currentContact",
    "currentExperience",
    "currentExperiences",
    "currentMediaItem",
    "currentProperty",
    "currentSelectedItems",
    "currentShare",
    "currentSharedExperience",
    "currentUser",
    "currentUsers",
    "currentUnivVideo",
    "elementMediaItems",
    "elements",
    "elementsDialog",
    "experienceMediaItems",
    "experiences",
    "flags",
    "hotspotData",
    "mediaItems",
    "members",
    "messages",
    "notifications",
    "properties",
    "recentExperiences",
    "searchedExperiences",
    "searchOptions",
    "singleShareExperience",
    "templateExperiences",
    "univCategories",
    "univCategoryVideos",
    "univVideos",
    "uploadProgress",
    "users",
    "loadingStatus",
    "assets",
    "assetCategories",
    "currentAssetCategory",
    "assetSelection",
    "assetFilters",
    "currentAsset",
  ],
  key: `TTX:${currentEnv()}`,
  storage,
};

const rootReducer = combineReducers(appReducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: reduxDevTools(),
  middleware(getDefaultMiddleware) {
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
    const middleware = getDefaultMiddleware({
      actionCreatorCheck: false, // TODO FIXME remove this, should be activated, default is: true
      immutableCheck: false, // TODO FIXME, remove this, should be activated, default is: true
      serializableCheck: false, // TODO FIXME, remove this, should be activated, default is: true
    });

    if (reduxLogger()) {
      middleware.push(createLogger());
    }

    middleware.push(saveAuthorizationToken);

    return middleware;
  },
  preloadedState: config.initial_state,
  reducer: persistedReducer,
});

if (reduxPersist()) {
  try {
    persistStore(store);
  } catch (err) {
    console.log(err);
  }
}

export default store;
