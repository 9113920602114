import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants/requestStatuses";

const initialState = {
  property: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const currentPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_PROPERTY:
      return { ...state, ...action.payload };
    case types.SET_CURRENT_PROPERTY_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_PROPERTY:
      return { ...initialState };
    default:
      return state;
  }
};
