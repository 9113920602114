import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UserTab {
  status?: string;
  dashboardStatus?: boolean;
  userCollection?: boolean;
}

// Record<string, GlobalTab> is equivalent to { [tabUrl: string]: UserTab }
export type IndividualUserTabs = Record<string, UserTab>;

// Record<string, GlobalTab> is equivalent to { [userId: string]: IndividualUserTabs }
type GlobalTabUsers = Record<string, IndividualUserTabs>;

export interface GlobalTabsData {
  globalTabs: GlobalTabUsers;
  noTabsOpen: boolean;
}

const initialState: GlobalTabsData = {
  globalTabs: {},
  noTabsOpen: false,
};

interface GlobalTabsDataState {
  globalTabsData: GlobalTabsData;
}

const GlobalTabsDataSlice = createSlice({
  initialState,
  name: "globalTabsData",
  reducers: {
    handleTab: (
      state,
      {
        payload,
      }: PayloadAction<{
        userId: string | number;
        tabId: string;
        newState: UserTab;
      }>,
    ) => {
      const { userId, tabId, newState } = payload;

      if (!state.globalTabs[userId] && tabId !== "") {
        state.globalTabs[userId] = {};
        state.globalTabs[userId][tabId] = newState;
      }

      if (newState.status === "active") {
        const tabIdBaseSplit = tabId?.split("/") || [];
        const propertyId = tabIdBaseSplit[2];
        const experienceId = tabIdBaseSplit[4];

        const approxTabId = Object.keys(state.globalTabs[userId]).find((key) => {
          const keyTabIdBaseSplit = key?.split("/") || [];
          const keyPropertyId = keyTabIdBaseSplit[2];
          const keyExperienceId = keyTabIdBaseSplit[4];
          return keyPropertyId === propertyId && keyExperienceId === experienceId;
        });

        if (tabId && approxTabId && tabId !== approxTabId) {
          const oldObj = state.globalTabs[userId];
          const keys = Object.keys(state.globalTabs[userId]);
          const objWithRenamedKey = keys.reduce((acc: Record<string, UserTab>, val) => {
            if (val === approxTabId) {
              acc[tabId] = oldObj[approxTabId];
            } else {
              acc[val] = oldObj[val];
            }
            return acc;
          }, {});
          state.globalTabs[userId] = objWithRenamedKey;
        }

        state.noTabsOpen = false;
        const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
          (tab) => state.globalTabs[userId][tab].status !== "closed",
        );

        openAndInactive.forEach((tab) => {
          state.globalTabs[userId][tab].status = "inactive";
        });

        state.globalTabs[userId][tabId] = newState;

        state.globalTabs[userId][tabId].status = "active";
      } else if (newState.status === "closed") {
        state.globalTabs[userId][tabId] = newState;
        const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
          (tab) => state.globalTabs[userId][tab].status !== "closed",
        );

        const numOpenAndInactive = openAndInactive.length;

        if (numOpenAndInactive > 0) {
          openAndInactive.forEach((tab) => {
            state.globalTabs[userId][tab].status = "inactive";
          });

          const lastItem = openAndInactive[numOpenAndInactive - 1];

          if (!newState?.dashboardStatus) {
            state.globalTabs[userId][lastItem].status = "active";
          }
        } else {
          state.noTabsOpen = true;
          state.globalTabs[userId] = {};
        }
      } else if (newState.status === "deactivate" && state.globalTabs[userId]) {
        const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
          (tab) => state.globalTabs[userId][tab].status !== "closed",
        );
        openAndInactive.forEach((tab) => {
          state.globalTabs[userId][tab].status = "inactive";
        });
      }
    },
    closeAllTabs: (
      state,
      {
        payload,
      }: PayloadAction<{
        userId: string;
        action: string;
      }>,
    ) => {
      const { userId, action } = payload;
      if (action === "closeAll") {
        state.globalTabs[userId] = {};
      }
    },
  },
});

export const { handleTab } = GlobalTabsDataSlice.actions;

export default GlobalTabsDataSlice.reducer;

export function globTabsSelector(propertyId: number, userId: number) {
  return (
    state: GlobalTabsDataState & { currentExperiences: Record<number, { experience: { userCollection: boolean } }> },
  ) => {
    const globTabs: IndividualUserTabs = state.globalTabsData?.globalTabs[userId] || {};

    const tabs: IndividualUserTabs = {};
    const experienceIds: number[] = [];

    Object.keys(globTabs).forEach((key) => {
      const [, , pid, , eid] = key.split("/");

      if (propertyId === +pid) {
        tabs[key] = globTabs[key];
        experienceIds.push(+eid);
      }

      if (state.currentExperiences[+eid]) {
        const currentExperience = state.currentExperiences[+eid];
        if (currentExperience) {
          tabs[key] = {
            ...tabs[key],
            userCollection: currentExperience.experience.userCollection,
          };
        }
      }
    });
    return { tabs, experienceIds };
  };
}
