import { type Theme, themes } from "./themes";

export interface AppConfig {
  readonly initial_state: {
    readonly themeSource: {
      readonly isNightModeOn: boolean;
      readonly source: "default";
    };
  };
  readonly themes: readonly Theme[];
}

const config: AppConfig = {
  initial_state: {
    themeSource: {
      isNightModeOn: false,
      source: "default",
    },
  },
  themes,
};

export default config;
