export const ELEMENT_STATUS = {
  DRAFT: "draft",
  CREATED: "created",
} as const;

export const ELEMENT_TYPE = {
  MAP: "map",
  LINK: "link",
  CONTENT: "content",
  VOICE_MEMO: "voiceMemo",
} as const;

export const ELEMENT_LINK_DESTINATION = {
  NEW_TAB: "newTab",
  MODAL: "modal",
} as const;

export const ELEMENT_SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
} as const;

export const ELEMENT_SHAPE = {
  SQUARE: "SQUARE",
  CIRCLE: "CIRCLE",
} as const;

export const ELEMENT_POSITIONING = {
  CONTENT: "content",
  EXPERIENCE: "experience",
} as const;

export const ELEMENT_SCOPE = {
  CONTENT: "content",
  EXPERIENCE: "experience",
} as const;

export const ELEMENT_COLOR = {
  DEFAULT: "DEFAULT",
  LIGHT: "LIGHT",
  DARK: "DARK",
} as const;

export const ELEMENT_COLOR_VALUE = {
  DEFAULT: "#F50561",
  LIGHT: "#fff",
  DARK: "#161616",
} as const;

export const ELEMENT_TEXT_COLOR_VALUE = {
  LIGHT: "#fff",
  DARK: "#333",
  DEFAULT: "#8f0339",
};

export const ELEMENT_TEXT_COLOR = {
  DEFAULT: "DEFAULT",
  LIGHT: "LIGHT",
  DARK: "DARK",
} as const;

export const ELEMENT_POPOVER_ACTION = {
  LINK: "link",
  SETTINGS: "settings",
  SHAPE: "shape",
  ICON: "icon",
  COLOR: "color",
  SIZE: "size",
  TEXT: "text",
  DELETE: "delete",
  HOTSPOT: "hotspot",
} as const;

export const ELEMENT_METHOD = {
  UPLOAD: "upload",
  RECORD: "record",
  FROM_LIBRARY: "from_library",
} as const;

export const MIC_PERMISSION = {
  DEFAULT: "default",
  DENIED: "denied",
  GRANTED: "granted",
} as const;

export const ELEMENT_FORM_STATE = {
  DEFAULT: "default",
  PREVIEW: "preview",
  REPLACE: "replace",
} as const;
