import { faArrowUpFromBracket } from "@fortawesome/pro-light-svg-icons";
import { AppBar, Box, Typography } from "@mui/material";
import { format, intervalToDuration, parseISO, sub } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BackButton from "@/components/UI/BackButton/BackButton";
import LabeledIconButton from "@/components/UI/LabeledIconButton/LabeledIconButton";
import { useAccessControl } from "@/hooks";
import { AnalyticsService } from "@/services/AnalyticsService";
import { currentPropertySelector, simpleValuesSelector } from "@/store/selectors";

import { DEFAULT_DATE_FORMAT } from "../enums";
import type { Analytics, DateRange, Widget } from "../types";
import { downloadBase64PDF, formatWidgetsValues, getCompareText } from "../utils";
import AnalyticsDateSelectorChip from "./AnalyticsDateSelectorChip";

interface AnalyticsResponse {
  file: string | null;
}

const styles = {
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "15px",
    weight: "400",
  },
  appBar: {
    padding: "0 0 0 16px",
    backgroundColor: "#f9f9f9",
    zIndex: "drawer",
    boxShadow: "none",
  },
  container: {
    gap: "16px",
    display: "flex",
    padding: "16px 0 16px 16px",
    background: "#fff",
    height: "130px",
    flexFlow: "column",
  },
  pageTitle: {
    textTransform: "capitalize",
    color: "primary.main",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "32px",
    fontFamily: "Inter",
  },
  goBack: {
    position: "absolute",
    top: "16px",
    right: "16px",
    zIndex: 10,
    "& > a": {
      fontFamily: "Inter",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  dateExportBlock: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
};

export default function AnalyticsOverviewHeader() {
  const { cardId = "", propertyId } = useParams();
  const navigate = useNavigate();
  const { analyticsDateRange: dateRange, analyticsDateChip: chipSelected } = useSelector(simpleValuesSelector);
  const { property } = useSelector(currentPropertySelector);
  const { canAccess } = useAccessControl();
  const [isDisabledCsv, setIsDisabledCsv] = useState(false);
  const [isLoadingExportPdf, setIsLoadingExportPdf] = useState(false);
  const isNotAdmin = !canAccess({ allowedPermissions: ["analytics_admin:read"] });

  // Redirect to the previous page if the user is a salesperson and cardId is not empty
  useEffect(() => {
    if (isNotAdmin && cardId !== "") {
      navigate(-1);
    }
  }, [isNotAdmin, cardId]);

  const handleBackClick = () => {
    // navigate back to the previous page.
    navigate(-1);
  };

  const getCompareDateRange = (startDate: string, duration: ReturnType<typeof intervalToDuration>) => ({
    startDate: format(
      sub(parseISO(startDate), {
        ...(duration.months && { months: duration.months }),
        days: duration.days ? duration.days + 1 : 1,
      }),
      DEFAULT_DATE_FORMAT,
    ),
    endDate: format(sub(parseISO(startDate), { days: 1 }), DEFAULT_DATE_FORMAT),
  });

  async function overviewCsv(
    propertyId: string,
    dateRange: DateRange,
    compareDateRange: DateRange,
    chipSelected: string,
  ) {
    const fetchAnalyticsOverview = async (): Promise<Analytics[]> => {
      try {
        const { resp, err } = (await AnalyticsService.getAnalyticsOverview(propertyId, {
          currentDateRange: dateRange,
          compareDateRange,
        })) as unknown as { resp: Analytics[]; err: Error };

        if (err) {
          throw new Error("Failed to fetch analytics overview");
        }

        return resp;
      } catch (error) {
        toast.error("Oops, something went wrong while fetching analytics overview.");
        throw error;
      }
    };

    const formatWidgetItem = (item: Widget, dateRange: DateRange, chipSelected: string) => ({
      label: item.label,
      value: formatWidgetsValues(item.value),
      compare: `${item.compare.sign}${formatWidgetsValues(item.compare.value)}${item.compare.symbol} ${getCompareText(
        item.label,
        dateRange,
        chipSelected,
        item.compare.value,
      )}`,
    });

    const formatChartItem = (item: readonly string[]) => ({
      label: item[1],
      value: `${item[0]}%`,
      compare: "",
    });

    const formatCardData = (card: Analytics, dateRange: DateRange, chipSelected: string) => {
      const widgetItems = card.data.widgets.map((item) => formatWidgetItem(item, dateRange, chipSelected));
      const chartItems = card.data.chart?.map((item) => formatChartItem(item)) || [];

      return [
        { label: card.label, value: "", compare: "" },
        ...widgetItems,
        ...chartItems,
        { label: "", value: "", compare: "" },
      ];
    };

    const formatDataAndPrepareCsv = (data: Analytics[]) => {
      const formattedData = data.flatMap((card) => formatCardData(card, dateRange, chipSelected));

      return formattedData.reduce((acc, item) => `${acc}${item.label},"${item.value}",${item.compare}\n`, ",,\n");
    };

    const resp = await fetchAnalyticsOverview();
    return formatDataAndPrepareCsv(resp);
  }

  async function experienceCsv(propertyId: string, dateRange: DateRange, compareDateRange: DateRange) {
    try {
      const { resp, err } = (await AnalyticsService.getAnalyticsExperienceDetailedReportCsv(propertyId, {
        currentDateRange: dateRange,
        compareDateRange,
      })) as unknown as { resp: ""; err: Error };

      if (err) {
        throw new Error("Failed to fetch analytics experience report.");
      }

      return resp;
    } catch (error) {
      toast.error("Oops, something went wrong while fetching analytics experience report.");
      throw error;
    }
  }

  async function assetCsv(propertyId: string, dateRange: DateRange) {
    try {
      const { resp, err } = (await AnalyticsService.getAnalyticsAssetDetailedReportCsv(propertyId, {
        currentDateRange: dateRange,
      })) as unknown as { resp: ""; err: Error };

      if (err) {
        throw new Error("Failed to fetch analytics asset report.");
      }

      return resp;
    } catch (error) {
      toast.error("Oops, something went wrong while fetching analytics asset report.");
      throw error;
    }
  }

  async function channelCsv(propertyId: string, dateRange: DateRange) {
    try {
      const { resp, err } = (await AnalyticsService.getAnalyticsChannelDetailedReportCsv(propertyId, {
        currentDateRange: dateRange,
      })) as unknown as { resp: ""; err: Error };

      if (err) {
        throw new Error("Failed to fetch analytics channel report.");
      }

      return resp;
    } catch (error) {
      toast.error("Oops, something went wrong while fetching analytics channel report.");
      throw error;
    }
  }

  const csvFunctions: Record<
    string,
    (propertyId: string, dateRange: DateRange, compareDateRange: DateRange, chipSelected: string) => Promise<string>
  > = {
    "": overviewCsv,
    experience: experienceCsv,
    asset: assetCsv,
    channel: channelCsv,
  };

  const handleExportCSVClick = async () => {
    setIsDisabledCsv(true);

    const duration = intervalToDuration({ start: parseISO(dateRange.startDate), end: parseISO(dateRange.endDate) });
    const compareDateRange = getCompareDateRange(dateRange.startDate, duration);

    try {
      const csvFunctionKey = isNotAdmin ? "experience" : cardId;
      const csvData = await (csvFunctions[csvFunctionKey]?.(
        propertyId as string,
        dateRange,
        compareDateRange,
        chipSelected,
      ) ?? "");

      const fileName = `${property?.name}_${cardId === "" ? "overview" : cardId}_report_${dateRange.startDate}_${dateRange.endDate}.csv`;
      const blob = new Blob([csvData], { type: "text/csv;charset=utf8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error(error);
      toast.error("Oops, something went wrong during export.");
    } finally {
      setIsDisabledCsv(false);
    }
  };

  const getAnalyticsPdfFile = async () => {
    const isSalesperson = canAccess({ allowedPermissions: ["analytics_user:read"] });

    const duration = intervalToDuration({
      start: parseISO(dateRange.startDate),
      end: parseISO(dateRange.endDate),
    });

    const query = {
      currentDateRange: dateRange,
      compareDateRange: {
        startDate: format(
          sub(parseISO(dateRange.startDate), {
            ...(duration.months && { months: duration.months }),
            days: duration.days ? duration.days + 1 : 1,
          }),
          DEFAULT_DATE_FORMAT,
        ),
        endDate: format(sub(parseISO(dateRange.startDate), { days: 1 }), DEFAULT_DATE_FORMAT),
      },
      chipSelected,
    };

    if (isSalesperson || cardId === "experience") {
      const { resp }: { resp: AnalyticsResponse | null } =
        await AnalyticsService.getAnalyticsExperienceDetailedReportPdf(propertyId, query);

      return resp?.file;
    }

    if (cardId === "channel") {
      const { resp }: { resp: AnalyticsResponse | null } = await AnalyticsService.getAnalyticsChannelDetailedReportPdf(
        propertyId,
        query,
      );

      return resp?.file;
    }

    if (cardId === "asset") {
      const { resp }: { resp: AnalyticsResponse | null } = await AnalyticsService.getAnalyticsAssetDetailedReportPdf(
        propertyId,
        query,
      );

      return resp?.file;
    }

    const { resp }: { resp: AnalyticsResponse | null } = await AnalyticsService.getAnalyticsOverviewPdf(
      propertyId,
      query,
    );

    return resp?.file;
  };

  const handleExportPDFClick = async () => {
    setIsLoadingExportPdf(true);

    try {
      const file = await getAnalyticsPdfFile();

      if (file) {
        downloadBase64PDF(
          file,
          `${property?.name}_${cardId === "" ? "overview" : cardId}_report_${dateRange.startDate}_${dateRange.endDate}.pdf`,
        );
      } else {
        toast.error(`Oops something went wrong during export.`);
      }
    } catch {
      toast.error(`Oops something went wrong during export.`);
    } finally {
      setIsLoadingExportPdf(false);
    }
  };

  return (
    <AppBar position="relative" sx={[styles.appBar]}>
      <Box sx={styles.container}>
        <Box>
          <Typography variant="h4" sx={styles.pageTitle}>
            <Box>{isNotAdmin ? "Experience Analytics" : `${cardId} Analytics`}</Box>
          </Typography>
        </Box>
        <Box sx={styles.dateExportBlock}>
          <AnalyticsDateSelectorChip />

          <LabeledIconButton
            icon={faArrowUpFromBracket}
            label="Export CSV"
            btnVariant="outlined"
            disabled={isDisabledCsv}
            onClick={() => void handleExportCSVClick()}
          />
          <LabeledIconButton
            data-testid="export-pdf"
            icon={faArrowUpFromBracket}
            label="Export PDF"
            onClick={() => void handleExportPDFClick()}
            btnVariant="outlined"
            disabled={isLoadingExportPdf}
          />
        </Box>
        {cardId && (
          <Box sx={styles.goBack}>
            <BackButton onClick={handleBackClick} />
          </Box>
        )}
      </Box>
    </AppBar>
  );
}
