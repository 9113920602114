import { createSelector } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants";
import type { CurrentUnivVideo, State } from "@/store/types";

const initialState: CurrentUnivVideo = {
  univVideo: undefined,
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const currentUnivVideoSelector = createSelector(
  (state: State) => state.currentUnivVideo,
  (currentUnivVideo) => {
    return { ...initialState, ...currentUnivVideo };
  },
);
