import { RetryManager } from "../utils/RetryManager";
import { getAccessToken } from "../utils/auth";

// TODO: centralize debug logging and parameterize (turn on/off) per environment settings

// silence console logging; comment out for local dev logging
const console = new Proxy({}, { get: () => () => {} });

const TERMINATE = Symbol("terminate without reconnect");

/**
 * Attempts to open a web socket connection, and keep it open, to listen for server-sent events.
 *
 * @param {string} url - the web sockets url to connect to for server events
 * @param {ENTITY[]} entities - type ENTITY = { name: ttx-backend/src/enums/action.enums.ts#ENTITY, options: object }
 * @param {object} options
 * @returns a function to close the web sockets connection
 */
export function serverEvents(url = "ws://localhost:4000", entities = [], options = {}) {
  const retry = new RetryManager();

  let pending;
  let ws;

  function close(event) {
    console.info("Socket closed.", event?.reason);
    console.debug(event);

    clearTimeout(pending);

    ws.removeEventListener("close", close);
    ws.removeEventListener("error", error);
    ws.removeEventListener("message", message);
    ws.removeEventListener("open", open);

    ws = null;

    if (event?.terminate !== TERMINATE) {
      const timeout = retry.getTimeout();

      console.info(`Attempting to reconnect socket after ${timeout} seconds.`);

      pending = setTimeout(connect, timeout);
    }
  }

  // encapsulate the connection logic so that it can be called by setTimeout
  // for retries; calling serverEvents recursively, instead, would break the
  // reference in the cleanup function returned so that cleanup wouldn't work
  function connect() {
    retry.attempt();

    // opening a WebSocket will result in either "open" or "error" being called
    ws = new WebSocket(url);

    ws.addEventListener("close", close);
    ws.addEventListener("error", error);
    ws.addEventListener("message", message);
    ws.addEventListener("open", open);
  }

  function error(err) {
    console.error("Socket encountered error: ", err?.message);
    console.debug(err);
    // call close so that all event listeners get removed; no memory leaks
    ws.close();
  }

  function message(message) {
    let payload;

    if (message?.data) {
      try {
        payload = JSON.parse(message.data);
      } catch {
        console.error(`WebSocket payload not parsed by JSON.parse.`);
        console.debug(message.data);
      }
    }

    if (payload) {
      document.dispatchEvent(new CustomEvent("WS_MESSAGE", { detail: { payload } }));
    }
  }

  function open() {
    const payload = { entities };

    if (options.userId) {
      payload.type = "subscribe";
      payload.token = getAccessToken();
      // This is a temporary solution until auth0 authorization is added to websocket.
      payload.userId = options.userId;
    } else if (options.propertyId && options.experienceId) {
      payload.type = "public_subscribe";
      payload.propertyId = options.propertyId;
      payload.experienceId = options.experienceId;
    }

    retry.reset();
    ws.send(JSON.stringify(payload));
  }

  connect();
  retry.reset(); // the initial connection attempt does not count as a retry

  // return a "cleanup" function that will close the web sockets connection
  // and remove event listeners to prevent memory leaks
  return () => close({ terminate: TERMINATE });
}
