import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  univCategoryVideos: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const univCategoryVideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_UNIV_CATEGORY_VIDEOS: {
      const { univCategoryVideos, status, err } = action.payload;
      return {
        ...state,
        univCategoryVideos,
        status,
        err,
        noResults: !univCategoryVideos.length,
      };
    }
    case types.SET_UNIV_CATEGORY_VIDEOS_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_UNIV_CATEGORY_VIDEOS:
      return { ...initialState };
    default:
      return state;
  }
};
