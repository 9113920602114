import { lazy } from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { AuthenticationGuard } from "@/components/AuthenticationGuard";
import { Auth0ProviderLayout, DashboardLayout, VisualBuilderLayout, dashboardLoader } from "@/layouts";
import AnalyticsLayout from "@/layouts/AnalyticsLayout";
import DropZoneLayout from "@/layouts/DropzoneLayout";
import RootLayout from "@/layouts/RootLayout";

import { ADMIN_ROUTE, DASHBOARD_ROUTE, PUBLIC_ROUTE, VISUAL_BUILDER_ROUTE } from "./constants";

const AnalyticsOverviewCard = lazy(() => import("../Dashboard/pages/AnalyticsOverview/AnalyticsDetailedPage"));
const AsyncAccounts = lazy(() => import("../Dashboard/pages/Accounts/Accounts"));
const AsyncAllUsers = lazy(() => import("../Dashboard/pages/Users/Users"));
const AsyncAnalyticsOverview = lazy(() => import("../Dashboard/pages/AnalyticsOverview/AnalyticsOverviewPage"));
const AsyncAssetLibraryFeatureFlagWrapper = lazy(
  () => import("../Dashboard/pages/AssetLibrary/AssetLibraryFeatureFlagWrapper"),
);
const AsyncComingSoon = lazy(() => import("../Dashboard/pages/ComingSoon/ComingSoon"));
const AsyncContactDetails = lazy(() => import("../Dashboard/pages/Contacts/ContactDetails"));
const AsyncContacts = lazy(() => import("../Dashboard/pages/Contacts/Contacts"));
const AsyncContentLibraries = lazy(() => import("../Dashboard/pages/ContentLibraries/ContentLibraries"));
const AsyncDashboard = lazy(() => import("../Dashboard/pages/Dashboard/Dashboard"));
const AsyncDoesNotExist = lazy(() => import("../Dashboard/pages/DoesNotExist/DoesNotExist"));
const AsyncExperience = lazy(() => import("../VisualBuilder"));
const AsyncExperiences = lazy(() => import("../Dashboard/pages/Experiences/Experiences"));
const AsyncIconLibrary = lazy(() => import("../Dashboard/pages/IconLibrary/IconLibrary"));
const AsyncMediaViewer = lazy(() => import("../VisualBuilder/components/MediaViewer/MediaViewer"));
const AsyncMyAccount = lazy(() => import("../Dashboard/pages/MyAccount/MyAccount"));
const AsyncPropertiesCreate = lazy(() => import("../Dashboard/pages/CreateProperty/CreateProperty"));
const AsyncProperties = lazy(() => import("../Dashboard/pages/Properties/Properties"));
const AsyncPropertyCoaches = lazy(() => import("../Dashboard/pages/Coaches/Coaches"));
const AsyncPropertyMembers = lazy(() => import("../Dashboard/pages/Members/Members"));
const AsyncPropertySettings = lazy(() => import("../Dashboard/pages/PropertySettings/PropertySettings"));
const AsyncSignIn = lazy(() => import("../Dashboard/pages/SignIn/SignIn"));
const AsyncUniversity = lazy(() => import("../Dashboard/pages/University/University"));
const AsyncUserProfile = lazy(() => import("../Dashboard/pages/UserProfile/UserProfile"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path={PUBLIC_ROUTE.PROPERTY.ASSET_LIBRARY.VIEW} element={<AsyncMediaViewer />} />
      <Route path={PUBLIC_ROUTE.PROPERTY.EXPERIENCES.VIEW} element={<AsyncExperience publicPage={true} />} />
      <Route path={PUBLIC_ROUTE.PROPERTY.EXPERIENCES.ALL} element={<AsyncExperience publicPage={true} />} />
      <Route path={PUBLIC_ROUTE.PROPERTY.EXPERIENCES.SINGLE} element={<AsyncExperience publicPage={true} />} />
      <Route element={<Auth0ProviderLayout />}>
        <Route path={PUBLIC_ROUTE.SIGN_IN} element={<AsyncSignIn />} />
        <Route element={<DashboardLayout />} loader={dashboardLoader}>
          <Route path={DASHBOARD_ROUTE.HOME} element={<AuthenticationGuard component={AsyncDashboard} />} />
          <Route element={<AnalyticsLayout />}>
            <Route
              path={DASHBOARD_ROUTE.ANALYTICS.LIST}
              element={<AuthenticationGuard component={AsyncAnalyticsOverview} />}
            />
            <Route
              path={DASHBOARD_ROUTE.ANALYTICS.VIEW}
              element={
                <AuthenticationGuard component={AnalyticsOverviewCard} allowedPermissions={["analytics_admin:read"]} />
              }
            />
          </Route>
          <Route element={<DropZoneLayout />}>
            <Route
              path={DASHBOARD_ROUTE.ASSET_LIBRARY.LIST}
              element={
                <AuthenticationGuard
                  component={AsyncAssetLibraryFeatureFlagWrapper}
                  allowedPermissions={["media_items:read"]}
                />
              }
            />
            <Route
              path={DASHBOARD_ROUTE.SETTINGS.ICONS}
              element={
                <AuthenticationGuard
                  component={AsyncIconLibrary}
                  allowedPermissions={["icons:create", "icons:update"]}
                />
              }
            />
          </Route>
          <Route
            path={DASHBOARD_ROUTE.COLLECTIONS.LIST}
            element={<AuthenticationGuard component={AsyncComingSoon} />}
          />
          <Route path={DASHBOARD_ROUTE.CONTACTS.LIST} element={<AuthenticationGuard component={AsyncContacts} />} />
          <Route
            path={DASHBOARD_ROUTE.CONTACTS.VIEW}
            element={<AuthenticationGuard component={AsyncContactDetails} />}
          />
          <Route
            path={DASHBOARD_ROUTE.SETTINGS.VIEW}
            element={
              <AuthenticationGuard component={AsyncPropertySettings} allowedPermissions={["properties:update"]} />
            }
          />
          <Route
            path={DASHBOARD_ROUTE.UNIVERSITY.LIST}
            element={<AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />}
          />
          <Route
            path={DASHBOARD_ROUTE.ACCOUNTS.LIIST}
            element={<AuthenticationGuard component={AsyncAccounts} allowedPermissions={["organizations:read"]} />}
          />
          <Route path={DASHBOARD_ROUTE.USERS.LIST} element={<AuthenticationGuard component={AsyncPropertyMembers} />} />
          <Route path={DASHBOARD_ROUTE.USERS.VIEW} element={<AuthenticationGuard component={AsyncUserProfile} />} />
          <Route
            path={DASHBOARD_ROUTE.EXPERIENCES.LIST}
            element={<AuthenticationGuard component={AsyncExperiences} />}
          />
          <Route path={DASHBOARD_ROUTE.PROFILE} element={<AuthenticationGuard component={AsyncMyAccount} />} />
        </Route>
        <Route element={<DashboardLayout />} loader={dashboardLoader}>
          <Route path={ADMIN_ROUTE.PROPERTIES.LIST} element={<AuthenticationGuard component={AsyncProperties} />} />
          <Route
            path={ADMIN_ROUTE.PROPERTIES.ADD}
            element={
              <AuthenticationGuard component={AsyncPropertiesCreate} allowedPermissions={["properties:create"]} />
            }
          />
          <Route
            path={ADMIN_ROUTE.ASSET_LIBRARIES.LIST}
            element={
              <AuthenticationGuard
                component={AsyncContentLibraries}
                allowedPermissions={["media_items:create", "media_items:update"]}
              />
            }
          />
          <Route
            path={ADMIN_ROUTE.ASSET_LIBRARIES.VIEW}
            element={<AuthenticationGuard component={AsyncContentLibraries} />}
          />
          <Route
            path={ADMIN_ROUTE.COACHES.LIST}
            element={<AuthenticationGuard component={AsyncPropertyCoaches} allowedPermissions={["coaches:create"]} />}
          />
          <Route path={ADMIN_ROUTE.COACHES.VIEW} element={<AuthenticationGuard component={AsyncUserProfile} />} />
          <Route
            path={ADMIN_ROUTE.USERS.LIST}
            element={
              <AuthenticationGuard component={AsyncAllUsers} allowedPermissions={["users:create", "users:delete"]} />
            }
          />
          <Route path={ADMIN_ROUTE.USERS.VIEW} element={<AuthenticationGuard component={AsyncUserProfile} />} />
          <Route
            path={ADMIN_ROUTE.UNIVERSITY.LIST}
            element={<AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />}
          />
          <Route
            path={ADMIN_ROUTE.UNIVERSITY.VIEW}
            element={<AuthenticationGuard component={AsyncUniversity} allowedPermissions={["universities:read"]} />}
          />
        </Route>
        <Route element={<VisualBuilderLayout />} loader={dashboardLoader}>
          <Route path={VISUAL_BUILDER_ROUTE.HOME} element={<AuthenticationGuard component={AsyncExperience} />} />
          <Route
            path={VISUAL_BUILDER_ROUTE.EXPERIENCES.COLLECT}
            element={<AuthenticationGuard component={AsyncExperience} />}
          />
          <Route
            path={VISUAL_BUILDER_ROUTE.EXPERIENCES.CUSTOMIZE}
            element={<AuthenticationGuard component={AsyncExperience} />}
          />
        </Route>
      </Route>
      <Route path={PUBLIC_ROUTE.NOT_FOUND} element={<AsyncDoesNotExist />} />
    </Route>,
  ),
);

export default router;
