import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";

const initialState = {
  experienceMediaItems: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const experienceMediaItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPERIENCE_MEDIA_ITEMS: {
      const { propertyId, experienceId, experienceMediaItems, status, err } = action.payload;

      const key = `${propertyId}/${experienceId}`;

      return {
        ...state,
        [key]: {
          ...(state[key] || {}),
          experienceMediaItems,
          status,
          err,
        },
      };
    }
    case types.SET_EXPERIENCE_MEDIA_ITEMS_REQUEST_STATUS: {
      const { propertyId, experienceId, status } = action.payload;
      const key = `${propertyId}/${experienceId}`;

      return { ...state, [key]: { ...(state[key] || {}), status } };
    }
    case types.RESET_EXPERIENCE_MEDIA_ITEMS: {
      const { propertyId, experienceId } = action.payload;
      if (propertyId && experienceId) {
        const key = `${propertyId}/${experienceId}`;
        return { ...state, [key]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
