import { KeyboardArrowDown } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { Alert, CheckMarkCircle, Error } from "../../icons";

import "./toast.css";

const ICON = {
  success: CheckMarkCircle,
  warning: Alert,
  error: Error,
  info: CheckMarkCircle,
};

const TITLE = {
  success: "Success!",
  warning: "Warning!",
  error: "Error!",
  info: "",
};

export default function ToastMessage(props) {
  const { type, message, config, toastId, containerId } = props;
  const Icon = ICON[type];
  const title = TITLE[type];
  const [showMoreIcon, setShowMoreIcon] = useState(config.showMore);
  const [showMore, setShowMore] = useState(config.showMore);
  const nodeRef = useCallback((node) => {
    if (node) {
      const isEllipsisActive = node.offsetWidth < node.scrollWidth;
      setShowMoreIcon(isEllipsisActive);
    }
  }, []);

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMore(true);
    setShowMoreIcon(false);
    toast.update(toastId, { containerId, autoClose: false, type: "info" });
  };

  return (
    <div className="toast-message-container">
      <div className="toast-icon-wrapper">
        <Icon fontSize="large" htmlColor={config.htmlColor} />
      </div>

      <div className="toast-content-wrapper">
        {title}
        <div className={`toast-message ${showMore ? "toast-message--wrap" : ""}`} ref={nodeRef}>
          {message}
        </div>
      </div>

      {showMoreIcon && (
        <button
          className="toast-icon-wrapper toast-icon-wrapper--more toast-icon-button"
          onClick={handleMoreClick}
          type="button"
        >
          <KeyboardArrowDown />
        </button>
      )}
    </div>
  );
}
