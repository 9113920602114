export enum CONTENT_TYPE_ENUM {
  THREE_HUNDRED_SIXTY_PHOTO = "360 Photo",
  THREE_DIMENSIONAL_MODEL = "3D Model",
  VIRTUAL_TOUR = "Virtual Tour",
  VIDEO = "Video",
  PHOTO = "Photo",
  PDF = "PDF",
  LINK = "Link",
  AUDIO = "Audio",
}

export const CONTENT_TYPE = {
  THREE_HUNDRED_SIXTY_PHOTO: "360 Photo",
  THREE_DIMENSIONAL_MODEL: "3D Model",
  VIRTUAL_TOUR: "Virtual Tour",
  VIDEO: "Video",
  PHOTO: "Photo",
  PDF: "PDF",
  LINK: "Link",
  AUDIO: "Audio",
} as const;

export type CONTENT_TYPE = (typeof CONTENT_TYPE)[keyof typeof CONTENT_TYPE];

export const CONTENT_TYPE_NAME = {
  [CONTENT_TYPE.THREE_HUNDRED_SIXTY_PHOTO]: "360 Photos",
  [CONTENT_TYPE.THREE_DIMENSIONAL_MODEL]: "3D Models",
  [CONTENT_TYPE.VIRTUAL_TOUR]: "Virtual Tours",
  [CONTENT_TYPE.VIDEO]: "Videos",
  [CONTENT_TYPE.PHOTO]: "Photos",
  [CONTENT_TYPE.PDF]: "Documents",
  [CONTENT_TYPE.LINK]: "WWW",
  [CONTENT_TYPE.AUDIO]: "Audio",
} as const;
