import * as types from "./types";

const initialState = {};

export const elementsDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ELEMENTS_DIALOG: {
      return {
        ...state,
      };
    }
    case types.SET_ELEMENTS_DIALOG: {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    case types.RESET_ELEMENTS_DIALOG: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return state;
  }
};
