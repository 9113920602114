import * as types from "./types";
import { REQUEST_STATUSES } from "../../../constants";
import { getNewList } from "../../../utils/helper";

const initialState = {
  mediaItemMembers: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const mediaItemMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIA_ITEM_MEMBERS: {
      const { propertyId, mediaItemMembers, status, err, query } = action.payload;
      const newMediaItemMembers = getNewList(query, state.mediaItemMembers, mediaItemMembers);
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          mediaItemMembers: newMediaItemMembers,
          status,
          err,
          noResults: !newMediaItemMembers.length,
          changed: new Date().getTime(),
        },
      };
    }
    case types.SET_MEDIA_ITEM_MEMBERS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_MEDIA_ITEM_MEMBERS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return initialState;
    }
    default:
      return state;
  }
};
